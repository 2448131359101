import React from "react";
import EduCrackAPI from "@lipihipi/client-sdk";
import {
  RemoteClasses,
  CreateSession,
  ListSession,
  FreeResourceDetail,
} from "@lipihipi/ec-livesession";
import { useHistory, useParams } from "react-router-dom";
import AuthAPI from "../../api/auth";

export const LiveSessionF = () => {
  const { id } = useParams();
  const history = useHistory();

  return (
    <CreateSession
      _id={id}
      getCourses={EduCrackAPI.course.list}
      getSubjects={EduCrackAPI.section.getSubject}
      getEducators={EduCrackAPI.educator.list}
      getBatches={EduCrackAPI.batchV2.list}
      getPrograms={EduCrackAPI.program.list}
      getSession={EduCrackAPI.session.get}
      createSession={EduCrackAPI.session.create}
      updateSession={EduCrackAPI.session.update}
      onCancel={() => {
        history.push("/live-session");
      }}
      upload={EduCrackAPI.asset.create}
      deleteFile={EduCrackAPI.asset.remove}
      getAssetUrl={EduCrackAPI.asset.getAssetUrl}
      afterAddOrEditSession={() => {
        history.push("/live-session");
      }}
    />
  );
};

export const LiveSessions = () => {
  const history = useHistory();
  const user: any = AuthAPI.getCurrentUser();
  return (
    <ListSession
      user={user}
      getSessions={EduCrackAPI.session.list}
      joinSession={EduCrackAPI.session.joinMeeting}
      onAdd={() => {
        history.push("/live-session/create");
      }}
      onEdit={(_id: any) => {
        history.push(`/live-session/edit/${_id}`);
      }}
      uploadFile={EduCrackAPI.asset.create}
      onCancelRequest={EduCrackAPI.asset.cancel}
      videoUpload={EduCrackAPI.session.uploadVideoFile}
      deleteSession={EduCrackAPI.session.deleteSession}
      afterSuccessVideoUpload={() => { }}
      onResourceClick={(_id: any) => {
        console.log(" resource_id: ", _id);
        history.push(`/live-session/resource/${_id}`);
      }}
    />
  );
};

export const StudLiveSessions = (props: any) => {
  const history = useHistory();
  return (
    <RemoteClasses
      getSessions={EduCrackAPI.session.list}
      joinSession={EduCrackAPI.session.joinMeeting}
      onView={(_id: any) => {
        history.push(`/course/remote-class/resource/${_id}`);
      }}
      courseId={props?.selectedCourse?._id}
      getFile={EduCrackAPI.asset.getAssetUrl}
    />
  );
};

export const StudSessionVideo = () => {
  const { id } = useParams();
  return (
    <FreeResourceDetail
      resourceId={id}
      getSessionById={EduCrackAPI.session.get}
      getAssetUrl={EduCrackAPI.asset.getAssetUrl}
    />
  );
};
