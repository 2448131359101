import EducrackAPI from "@lipihipi/client-sdk";
import React, { FC } from "react";
// import logoWhite from '../../images/svg/logo.svg';

interface LeftPanelProps {
  title?: string;
  description?: string;
  imageBaseUrl: any;
}

const LeftPanel: FC<LeftPanelProps> = ({
  title,
  imageBaseUrl,
}: LeftPanelProps) => {
  const centerLogo = localStorage.getItem("logo");
  const { logo, socials, styles } = EducrackAPI.getConfig();

  return (
    <div className="user-structure--info">
      <div className="info-header">
        <img
          src={EducrackAPI.asset.getAssetUrl(
            centerLogo ? centerLogo : logo?.key
          )}
          alt="Logo"
          width="150px"
        />
      </div>
      <div className="info-body text-center">
        <h1>
          {title ||
            styles?.onboardingText ||
            "Enter a unique world of competitive exam preparation"}
        </h1>
        {/* <p>{description || "-------  CRACK IT NOW   -------"}</p> */}
        <img src={imageBaseUrl(styles?.onboarding)} alt="logo" />
      </div>
      <div className="info-footer">
        <ul>
          {socials?.meta && (
            <li>
              <a
                className="fb"
                href="https://www.facebook.com/educrackindia/"
                target="_blank"
              >
                Meta(Facebook)
              </a>
            </li>
          )}
          {socials?.linkedin && (
            <li>
              <a
                className="lk"
                href="https://www.linkedin.com/company/educrack/"
                target="_blank"
              >
                LinkedIn
              </a>
            </li>
          )}
          {socials?.insta && (
            <li>
              <a
                className="ins"
                href="https://www.instagram.com/educrack.india/"
                target="_blank"
              >
                Instagram
              </a>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default LeftPanel;
