import React from "react";
import { ResetPassword } from "@lipihipi/ec-student-web";
import EducrackAPI from "@lipihipi/client-sdk";
import { useParams } from "react-router-dom";

const ResetPasswordPage = () => {
  const { userId, token } = useParams();
  console.log({ userId, token });
  return (
    <ResetPassword
      userId={userId}
      token={token}
      resetPassword={EducrackAPI.user.resetPassword}
      validatePasswordLink={EducrackAPI.user.validatePasswordLink}
      imageBaseUrl={EducrackAPI.asset.getAssetUrl}
    />
  );
};

export default ResetPasswordPage;
