import React, { useEffect, useState } from "react";
import { Loader, PageHeader } from "@lipihipi/ec-ui";
import EducrackAPI from "@lipihipi/client-sdk";
import { CircularProgress } from "./circular-progress/circular-progress";

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  // const [purchasedBatch, setPurchasedBatch] = useState<any>({});
  const [interview, setDataInter] = useState<any>({});
  const [testList, setTestBundleEnroll] = useState<any>({});
  const [resumePercentage, setResumePercentage] = useState<any>();
  useEffect(() => {
    // getBatches();
    userInter();
    userTest();
    getResume();
  }, []);
  // const getBatches = async () => {
  //   await EducrackAPI.batchV2
  //     .listPurchasedBatches({ all: true, populate: true })
  //     .then((res: any) => {
  //       setIsLoading(false);
  //       setPurchasedBatch(res?.data);
  //     })
  //     .catch((error: any) => {
  //       console.log(error);
  //     });
  // };
  const getResume = async () => {
    //@ts-ignore
    await EducrackAPI.resume.me().then((res: any) => {
      let resumeComplete: any = 0;
      if (res.data?.educations && res.data.educations.length > 0) {
        resumeComplete = resumeComplete + 20;
      }
      if (res.data?.internships && res.data.internships.length > 0) {
        resumeComplete = resumeComplete + 20;
      }
      if (res.data?.projects && res.data.projects.length > 0) {
        resumeComplete = resumeComplete + 20;
      }
      if (res.data?.skills && res.data.skills.length > 0) {
        resumeComplete = resumeComplete + 20;
      }
      if (res.data?.firstName) {
        resumeComplete = resumeComplete + 20;
      }
      setResumePercentage(resumeComplete);
    });
  };
  const userInter = async () => {
    await EducrackAPI.userInterview
      .list({ populate: true, all: true })
      .then((res: any) => {
        setDataInter(res?.data);
      })
      .catch((error: any) => {
        console.error(error);
      });
  };
  const userTest = async () => {
    await EducrackAPI.userTestBundle
      .report()
      .then(async (res: any) => {
        setIsLoading(false);
        setTestBundleEnroll(res?.data);
      })
      .catch((error: any) => {
        console.error(error);
      });
  };
  return (
    <>
      {isLoading && <Loader />}
      <section className="dashboard-block">
        <h3>Dashboard</h3>
        <div className="dashboard-block_wrap">
          <div className="info">
            <div className="row">
              <div className="col-md-3">
                <h3>Resume Completeness</h3>
                <CircularProgress
                  stroke="#F15CA4"
                  percentage
                  progressValue={resumePercentage ? resumePercentage : 0}
                  totalProgress={100}
                  primaryColor="#00A689"
                />
              </div>
              {testList?.length > 0 &&
                testList?.map((tests: any) => {
                  return (
                    <div className="col-md-5 mt-5 mt-md-0">
                      <h3>{tests.name}</h3>
                      <div className="d-flex">
                        <CircularProgress
                          stroke="#9071CF"
                          progressValue={tests.attempts ? tests.attempts : 0}
                          totalProgress={tests.tests.length}
                        />

                        <div className="pl-5 pl-md-3 pl-lg-5">
                          <p>Total : {tests.tests.length}</p>
                          <p>Attempted: {tests.attempts}</p>
                          {/* <p>Latest Score: <strong>89%ile</strong></p> */}
                          <a href={`/course/tests/details/${tests?._id}`}>
                            View more
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}
              {interview?.userInterview?.length > 0 &&
                interview?.userInterview?.map((interviews: any) => {
                  return (
                    <div className="col-md-4 mt-5 mt-md-0">
                      <h3>{interviews.interview.name}</h3>
                      <div className="d-flex">
                        <CircularProgress
                          stroke="#71CFB9"
                          progressValue={
                            interviews?.attended?.length
                              ? interviews?.attended?.length
                              : 0
                          }
                          totalProgress={interviews?.interview?.noOfLinks}
                        />

                        <div className="pl-5 pl-md-3 pl-lg-5">
                          <p>Total : {interviews?.interview?.noOfLinks}</p>
                          <p>Attempted: {interviews?.attended?.length}</p>
                          <a href={`/course/interviews/${interviews._id}`}>
                            View more
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </section>

      <section className="dashboard-block">
        <h3>Recent Assignments</h3>
        <div className="dashboard-block_wrap">
          {interview?.userInterview?.length > 0 &&
            interview?.userInterview?.map((interviews: any) => {
              return (
                interviews?.attended?.length > 0 && (
                  <div className="assignment-row">
                    <div className="d-flex align-items-center mb-3 mb-md-0">
                      <p>{interviews.interview.name}</p>
                      <span>Interview</span>
                    </div>

                    <div className="d-flex align-items-center">
                      {/* <p>21-03-2021</p> */}
                      <a href={`/course/interviews/${interviews._id}`}>
                        Attempt now
                      </a>
                    </div>
                  </div>
                )
              );
            })}
        </div>
      </section>

      <section className="dashboard-block d-none">
        <h3>Upcoming Live sessions</h3>
        <div className="dashboard-block_wrap">
          <h5>you don’t have any Upcoming Live sessions at this moment!</h5>
        </div>
      </section>

      <div className="dashboard d-none">
        <div className="row">
          <div className="col-md-3">
            <a href="/resume">
              <div className="dashboard_box">
                <CircularProgress
                  // title="Total Score"
                  progressValue={resumePercentage ? resumePercentage : 0}
                  totalProgress={100}
                  primaryColor="#00A689"
                />
                <strong>Resume Completeness</strong>
              </div>
            </a>
          </div>
          {testList?.length > 0 &&
            testList?.map((tests: any) => {
              return (
                <div className="col-md-4">
                  <a href={`/course/tests/details/${tests?._id}`}>
                    <div
                      className="dashboard_box"
                      style={{ background: "#DFFFF0" }}
                    >
                      <CircularProgress
                        // title="Total Score"
                        progressValue={tests.attempts ? tests.attempts : 0}
                        totalProgress={tests.tests.length}
                        primaryColor="#00A689"
                      />
                      <strong>{tests.name}</strong>
                    </div>
                  </a>
                </div>
              );
            })}
          {interview?.userInterview?.length > 0 &&
            interview?.userInterview?.map((interviews: any) => {
              return (
                <div className="col-md-5">
                  <a href={`/course/interviews/${interviews._id}`}>
                    <div
                      className="dashboard_box"
                      style={{ background: "#FFDFEE" }}
                    >
                      <CircularProgress
                        // title="Total Score"
                        progressValue={
                          interviews?.attended?.length
                            ? interviews?.attended?.length
                            : 0
                        }
                        totalProgress={interviews?.interview?.noOfLinks}
                        primaryColor="#00A689"
                      />
                      <strong>{interviews.interview.name}</strong>
                    </div>
                  </a>
                </div>
              );
            })}
          {/* <div className="col-md-5">
            <div className="dashboard_box" style={{ background: "#FEFFDF" }}>
              <div className="dashboard_header">
                <h3>CRT Program</h3>
              </div>

              <ul>
                <li>
                  <span>
                    <img
                      src="https://educrack.com/static/asset/svg/new/live-batch.svg"
                      alt=""
                    />
                  </span>
                  Recorded Program
                </li>
                <li>
                  <span>
                    <img
                      src="https://educrack.com/static/asset/svg/new/date.svg"
                      alt=""
                    />
                  </span>
                  Start Date - 21st Mar 2022
                </li>
              </ul>

              <div className="wrap">
                <div className="progress-wrap">
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: "25%" }}
                      aria-valuenow={25}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div>
                  <em>75%</em>
                </div>
              </div>
            </div>
          </div> */}

          {/* <div className="col-md-5">
            <div className="dashboard_box" style={{ background: "#DFE8FF" }}>
              <div className="dashboard_header">
                <h3>MBA Comprehensive 2022</h3>
                <ul>
                  <li>Mon, Tue, Wed</li>

                  <li>10 am - 12 pm</li>
                </ul>
              </div>

              <ul>
                <li>
                  <span>
                    <img
                      src="https://educrack.com/static/asset/svg/new/live-batch.svg"
                      alt=""
                    />
                  </span>
                  Live Program
                </li>
                <li>
                  <span>
                    <img
                      src="https://educrack.com/static/asset/svg/new/date.svg"
                      alt=""
                    />
                  </span>
                  Start Date - 21st Mar 2022
                </li>
              </ul>

              <div className="wrap">
                <div className="progress-wrap">
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: "25%" }}
                      aria-valuenow={25}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div>
                  <em>75%</em>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
