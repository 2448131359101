
  import { toast } from 'react-toastify';

  const defaultConfig:object = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
  }

  const show = (notification:string , config : object = defaultConfig) =>{
    const toastConfig = {...defaultConfig , ...config}  
    toast(notification, toastConfig);     
  }

  const info = (notification:any, config : object = defaultConfig) =>{
    const toastConfig = {...defaultConfig , ...config} 
    toast.info(notification, toastConfig); 
  }

  const success = (notification:any, config : object = defaultConfig) =>{
    const toastConfig = {...defaultConfig , ...config} 
    toast.success(notification, toastConfig); 
  }

  const warn = (notification:any, config : object = defaultConfig) =>{
    const toastConfig = {...defaultConfig , ...config} 
    toast.warn(notification, toastConfig); 
  }
  const error = (notification:any, config : object = defaultConfig) =>{
    const toastConfig = {...defaultConfig , ...config} 
    toast.error(notification, toastConfig); 
  }


  const NotificationService = {
    show: show,
    info: info,
    success: success,
    warn:warn,
    error:error
  }
  export default NotificationService;