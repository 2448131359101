import EducrackAPI from "@lipihipi/client-sdk";
import { IUserDocument } from "@lipihipi/client-sdk/dist/user";
import Pusher from "pusher-js";
import { ActionType } from "../Reducer";
import NotificationService from "./notificationService";

interface ILoginParms {
  email: string;
  password: string;
}

export enum UserRole {
  USER = "user",
  EDUCATOR = "educator",
  ADMIN = "admin",
  SUPERADMIN = "superadmin",
  CONTRIBUTOR = "contributor",
}

const GrantResources = EducrackAPI.auth.GrantResources;

const token = localStorage.getItem("token");

if (token) {
  EducrackAPI.setToken(token);
}
let currentUser: IUserDocument | any = null;
let evaluatedPermissions: any = {};

const isLoggedIn = () => {
  if (currentUser) {
    return true;
  }

  const status = localStorage.getItem("token") !== null;
  if (status) {
    AuthAPI.isAuthenticated = true;
  }
  return status;
};

const setCurrentUser = () => {
  return EducrackAPI.user.me().then((response: any) => {
    // @ts-ignore
    currentUser = response.data;
    pusherInit(currentUser?._id);
    const permissions = response.data.permissions || [];
    evaluatedPermissions = permissions.reduce((acc: any, it: any) => {
      // @ts-ignore
      const perm = GrantResources[it];
      if (perm) {
        acc[perm] = perm;
      }
      return acc;
    }, {});
    // dispatch({
    //   type: ActionType.GET_USER_SUCCESS,
    //   payload: currentUser,
    // });
    if (currentUser?.center?.logo) {
      localStorage.setItem("logo", currentUser?.center?.logo);
    }
    if (currentUser?.center?.name) {
      localStorage.setItem("centerName", currentUser?.center?.name);
    }
    return currentUser;
  });
};

const getUserGrants = () => {
  return evaluatedPermissions;
};

const hasResourceGrant = (resource: string) => {
  if (currentUser && currentUser.role === UserRole.SUPERADMIN) {
    return true;
  }

  const grant = evaluatedPermissions[resource];
  return Boolean(grant);
};

const getCurrentUser = () => {
  return currentUser;
};

class HttpError extends Error {
  status: number;
  constructor(status: number, message: string) {
    super(message);
    this.name = "HttpError";
    this.status = status;
  }
}

const login = ({ email, password }: ILoginParms) => {
  return EducrackAPI.auth.login({ email, password }).then(
    (response) => {
      const token = response.data.token;
      localStorage.setItem("token", token);
      AuthAPI.isAuthenticated = true;
      return setCurrentUser();
    },
    ({ response }) => {
      console.log(response);
      if (response.status === 401) {
        throw new HttpError(
          response.status,
          (response.data && response.data.message) ||
            "Invalid Username or Password."
        );
      } else {
        throw new HttpError(
          response.status,
          (response.data && response.data.message) || "Internal Server Error."
        );
      }
    }
  );
};

const logout = () => {
  localStorage.clear();
  currentUser = null;
};

const getRedirectUrl = (user: IUserDocument | null) => {
  let url = "/login";

  if (user) {
    switch (user.role) {
      case UserRole.USER:
        url = "/course/remote-class";
        break;
      case UserRole.ADMIN:
      case UserRole.SUPERADMIN:
        url = "/dashboard";
        break;
      case UserRole.EDUCATOR:
        url = "/dashboard";
        break;
      case UserRole.CONTRIBUTOR:
        url = "/dashboard";
        break;
      default:
        url = "/login";
    }
  }

  return url;
};

const getCacheUserMe = () => {
  console.log("user.me api called, data returned from cache");
  return new Promise((resolve, reject) => {
    const user = getCurrentUser();
    resolve({ data: user });
  });
};

const pusherInit = (userId: string = "") => {
  const token = localStorage.getItem("token");
  console.log("userId", userId);
  // Initialize Pusher (One time task)
  const pusher = new Pusher("a96b602e975cfa784a48", {
    authEndpoint:
      "https://uat-api.educrack.com/chat-service/webhooks/v1/pusher/auth",
    // authEndpoint : 'https://dev-dashboard.educrack.com/api/v1/users/pusher/token',
    cluster: "ap2",
    auth: {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    },
  });

  // Bind to channels for messages. Here is the example for User channel binding
  // const userId =  '606750e4401a692520290e00';
  const myChannel = pusher.subscribe(`private-${userId}`); // me is user's ID

  myChannel.bind("notification", (msg: any) => {
    console.log("Received MSG - notification", msg);
    // NotificationService.error(msg);
  });

  myChannel.bind("PrivateMessages", (msg: any) => {
    console.log("Received MSG - PrivateMessages", msg);
  });

  myChannel.bind("pusher:subscription_succeeded", (msg: any) => {
    console.log("Subscribed to channel subscription_succeeded", msg);
  });
  myChannel.bind("pusher:subscribe", (msg: any) => {
    console.log("Subscribed to channel subscribe", msg);
  });
  myChannel.bind("pusher:error", (msg: any) => {
    console.log("Subscribed to channel error", msg);
    NotificationService.error(msg);
  });

  pusher.connection.bind("state_change", function (states: any) {
    // states = {previous: 'oldState', current: 'newState'}
    console.log("Channels current state is " + states.current);
    // NotificationService.show("Notification Service: " + states.current);
  });

  pusher.connection.bind("error", function (error: any) {
    console.error("connection error", error);
    // NotificationService.error(error);
  });
};

const AuthAPI = {
  login,
  logout,
  isLoggedIn,
  setCurrentUser,
  isAuthenticated: false,
  getCurrentUser,
  getRedirectUrl,
  getUserGrants,
  hasResourceGrant,
  getCacheUserMe,
};

export default AuthAPI;
