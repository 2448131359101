import EducrackAPI from "@lipihipi/client-sdk";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import AuthAPI from "../../api/auth";
import { LeftPanel } from "../common";
import swal from "sweetalert";

const AssignedTestInterview = ({ isPsychometric }: any) => {
  const _window = window as any;

  const { search, pathname } = useLocation();
  const query = new URLSearchParams(search);
  const history = useHistory();

  const [assignedDetails, setAssignedDetails] = useState<{
    interviewname?: string;
    interview?: string;
    testname?: string;
    test?: string;
    username: string;
    token?: string;
  }>({
    username: "",
  });

  useEffect(() => {
    const payload = { token: String(query.get("token")) };
    const getData = async () => {
      let apiResponse;
      try {
        if (isPsychometric) {
          apiResponse = await EducrackAPI.psychometricUserTest.assingVerify(
            payload
          );
        } else {
          const method = pathname.includes("test")
            ? EducrackAPI.userTest.assingVerify
            : EducrackAPI.userInterview.assingVerify;
          apiResponse = await method(payload);
        }
        const { data } = apiResponse;
        if (data?.message === "error") {
          apiError();
        }
        if (data?.token) {
          setAssignedDetails(data);
          localStorage.setItem("token", data?.token);

          EducrackAPI.setToken(data?.token);
          AuthAPI.setCurrentUser();
        }
      } catch {
        apiError();
      }
    };
    getData();
  }, []);

  const handleSubmit = () => {
    if (pathname.includes("test")) attemptTest();
    else attemptInterview();
  };

  const attemptInterview = async () => {
    try {
      const { data: interviewData }: any =
        await EducrackAPI.userInterview.subscribeInterview({
          interviewId: String(assignedDetails?.interview),
        });
      if (interviewData?._id) {
        const { data } = await EducrackAPI.userInterview.attend(
          String(interviewData?._id)
        );
        _window.open(data.interview_url);
      }
    } catch {
      apiError();
    }
  };

  const apiError = () => {
    swal({
      title: "Error",
      text: "Server error!",
      icon: "error",
      dangerMode: false,
    }).then(() => {
      history.push(`/login`);
    });
  };

  const attemptTest = async () => {
    try {
      let response: any = {};
      if (isPsychometric) {
        response = await EducrackAPI.psychometricUserTest.create({
          testId: String(assignedDetails?.test),
        });
        console.log(response);
      } else {
        response = await EducrackAPI.userTest.create({
          testId: String(assignedDetails?.test),
        });
      }
      const { enrollmentId } = response?.data;

      if (enrollmentId) {
        const { data } = await EducrackAPI.testAttempt.list({
          test: enrollmentId,
        });

        if (data && isPsychometric) {
          history.push(`/psychometric-test/instructions/${enrollmentId}`);
        } else if (data) {
          history.push(`/course/test/instructions/${enrollmentId}`);
        }
      }
    } catch {
      apiError();
    }
  };

  return (
    <main className="user-structure">
      <LeftPanel imageBaseUrl={EducrackAPI.asset.getAssetUrl} />
      <div className="user-structure--form">
        <div className="wrap">
          <h2>{`Hello, ${assignedDetails?.username}`}</h2>
          {assignedDetails?.interviewname && (
            <h3>
              Interview name assigned to you:
              <h2>
                <i>{assignedDetails?.interviewname}</i>
              </h2>
            </h3>
          )}
          {assignedDetails?.testname && (
            <h3>
              Test name assigned to you:
              <h2>
                <i>{assignedDetails?.testname}</i>
              </h2>
            </h3>
          )}

          <div className="button-group">
            <button
              type="submit"
              className="btn btn-primary"
              style={{ width: "100%" }}
              onClick={() => handleSubmit()}
            >
              Attempt Now
            </button>
          </div>
        </div>
      </div>
    </main>
  );
};

export default AssignedTestInterview;
