import React from "react";
import EducrackAPI from "@lipihipi/client-sdk";
import {
  SupportTicketList,
  UserComplaint,
  SupportReplies,
} from "@lipihipi/ec-support/dist";
import { useHistory, useParams } from "react-router-dom";

const TicketList = () => {
  const history = useHistory();
  return (
    <SupportTicketList
      onAdd={() => history.push("/support/create")}
      onGetChat={(ticketId) => history.push(`/support/${ticketId}`)}
      getSupportTickets={EducrackAPI.support.list}
      breadCrumbs={[{ title: "Support", link: "#" }]}
    />
  );
};

const ComplaintSection = () => {
  const history = useHistory();
  return (
    <UserComplaint
      isEducator={false}
      onCreate={EducrackAPI.support.create}
      createAsset={EducrackAPI.asset.create}
      getAssetUrl={EducrackAPI.asset.getAssetUrl}
      afterGenerateTicket={() => history.push("/support")}
      breadCrumbs={[
        { title: "Support", link: "/support" },
        { title: "Create new ticket", link: "/support/create" },
      ]}
    />
  );
};

const Replies = () => {
  const { ticketId } = useParams();
  return (
    <SupportReplies
      ticketId={ticketId}
      onCreate={EducrackAPI.support.create}
      createAsset={EducrackAPI.asset.create}
      getAssetUrl={EducrackAPI.asset.getAssetUrl}
      getSupportChat={EducrackAPI.support.get}
      breadCrumbs={(ticketId: string) => {
        return [
          { title: "Support", link: "/support" },
          { title: `${ticketId}`, link: '#' },
        ];
      }}
    />
  );
};

const SupportListAdmin = () => {
  const history = useHistory();
  return (
    <SupportTicketList
      isAdmin={true}
      onAdd={() => {}}
      onGetChat={(ticketId) => history.push(`/admin/support/${ticketId}`)}
      getSupportTickets={EducrackAPI.support.list}
      breadCrumbs={[{ title: "Support", link: "#" }]}
    />
  );
};

const AdminReplies = () => {
  const { ticketId } = useParams();
  return (
    <SupportReplies
      isAdmin={true}
      ticketId={ticketId}
      onCreate={EducrackAPI.support.create}
      createAsset={EducrackAPI.asset.create}
      getAssetUrl={EducrackAPI.asset.getAssetUrl}
      getSupportChat={EducrackAPI.support.get}
      breadCrumbs={(ticketId: string) => {
        return [
          { title: "Support", link: "/admin/support" },
          { title: `${ticketId}`, link: '#' },
        ];
      }}
    />
  );
};

export {
  TicketList,
  ComplaintSection,
  Replies,
  SupportListAdmin,
  AdminReplies,
};
