import React, { useContext, useEffect } from "react";
import EducrackAPI from "@lipihipi/client-sdk";
import { Wall } from "@lipihipi/ec-wall";
// import { useHistory, useParams } from "react-router-dom";
import AuthAPI from "../../api/auth";
import { AppContext } from "../../App";
import { ActionType } from "../../Reducer";

export const WallList = (props: any) => {
  const user: any = AuthAPI.getCurrentUser();
  const { dispatch } = useContext<any>(AppContext);
  useEffect(() => {
    dispatch({
      type: ActionType.SET_TOP_MENU,
      payload: "home",
    });
  }, []);

  const currentModulePermission = user?.permissions.filter(
    (v: any) => v?.type === "WALL"
  );

  return (
    <Wall
      user={user}
      type="post"
      // getProfile={EducrackAPI.user.get}
      getBatchByStudent={EducrackAPI.batchV2.listPurchasedBatches}
      getCourse={EducrackAPI.course.list}
      // getEducatorProfile={EducrackAPI.educator.get}
      createWallResponse={EducrackAPI.wallResponse.create}
      getPost={EducrackAPI.wall.get}
      updateWallResponse={EducrackAPI.wallResponse.update}
      createAsset={EducrackAPI.asset.create}
      createPost={EducrackAPI.wall.create}
      getWallList={EducrackAPI.wall.list}
      getAssetUrl={EducrackAPI.asset.getAssetUrl}
      removePost={EducrackAPI.wall.remove}
      removeComment={EducrackAPI.wallComment.remove}
      createComment={EducrackAPI.wallComment.create}
      getEducators={EducrackAPI.educator.list}
      selectedCourse={props.selectedCourse}
      getTeachers={EducrackAPI.educator.list}
      imageBaseUrl={EducrackAPI.asset.getAssetUrl()}
      updateWall={EducrackAPI.wall.update}
      currentModulePermission={
        currentModulePermission && currentModulePermission[0]?.actions
      }
    />
  );
};
