import React from "react";
import EducrackAPI from "@lipihipi/client-sdk";
import { Doubt } from "@lipihipi/ec-wall";
// import { useHistory, useParams } from "react-router-dom";
import AuthAPI from "../../api/auth";

export const DoubtList = (props: any) => {
  const user = AuthAPI.getCurrentUser();
  return (
    <Doubt
      imageBaseUrl={EducrackAPI.asset.getAssetUrl()}
      selectedCourse={props.selectedCourse}
      user={user}
      // getProfile={EducrackAPI.user.get}
      getCourses={EducrackAPI.course.list}
      getEducators={EducrackAPI.educator.list}
      // getEducatorProfile={EducrackAPI.educator.get}
      createDoubt={EducrackAPI.doubt.create}
      updateDoubt={EducrackAPI.doubt.update}
      removeDoubt={EducrackAPI.doubt.remove}
      listDoubt={EducrackAPI.doubt.list}
      // getMyBatchSubjects={EducrackAPI.batchV2.getMyBatchSubjects}
      getMyBatchSubjectTeachers={EducrackAPI.educator.list}
      getAssetUrl={EducrackAPI.asset.getAssetUrl}
      createAsset={EducrackAPI.asset.create}
      removeDoubtComment={EducrackAPI.doubtComment.remove}
      createDoubtComment={EducrackAPI.doubtComment.create}
      createDoubtResponse={EducrackAPI.doubtResponse.create}
      updateDoubtResponse={EducrackAPI.doubtResponse.update}
      getPurchasedBatchList={EducrackAPI.batchV2.listPurchasedBatches}
    />
  );
};
