import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import EducrackAPI from "@lipihipi/client-sdk";
import {Loading} from '../ui/loading';
import swal from 'sweetalert';

const PaymentStatus = () => {
  const history = useHistory();
  const { orderId } = useParams();

  const [paymentRes, setPaymentRes] = useState<any>({payment_status: null});

  const checkPaymentStatus = () => {
    EducrackAPI.payment.getPaymentStatus(orderId).then(({data}:any) => {
      setPaymentRes(data);
      })
      .catch((err) => {
        console.log("Error getting users", err);
        swal({
          title: 'Error',
          text: 'Server error!',
          icon: 'error',
          dangerMode: false,
        });
      });
  };

  const handleBackClick = () => {
    if(paymentRes.order_type === "TESTBUNDLE") {
      history.push('/course/tests');
    }else if(paymentRes.order_type === "INTERVIEW") {
      history.push('/course/interviews');
    }else if(paymentRes.order_type === "PROGRAM") {
      history.push('/course/batch');
    }else{
      history.push('/dashboard');
    }
    
  };
  useEffect(checkPaymentStatus, []);

  if(!paymentRes.payment_status) {
    return <Loading/>
  }


  return (
    <>
      <section className="main-structure">
      
    <div style={{backgroundColor:"#F5F9FF",paddingTop:50,paddingBottom:50}}>
      <div
        className="check m-auto "
        style={{ width: '50%', textAlign: 'center' }}
      >
        {' '}
        <div className="shadow p-3 mb-5 bg-white rounded m-auto ">
          <div className="headingcheck m-auto bold">
            <h1 className="mt-4 mb-5"style={{ fontSize: 30, color: 'black' }}>
              Thankyou for choosing us.
            </h1>
            <h6 style={{marginTop:0}}>Your Payment details are as follows</h6>
            {paymentRes.order && <div>
              <p>orderAmount: {paymentRes.order.orderAmount}</p>
              <p>txStatus: {paymentRes.order.txStatus}</p>
              <p>referenceId: {paymentRes.order.referenceId}</p>
              <p>txMsg: {paymentRes.order.txMsg}</p>
              <p>paymentMode: {paymentRes.order.paymentMode}</p>

            </div>}
          </div>
               <button onClick ={handleBackClick} className="mt-5 mb-4"style={{backgroundColor:'#FFE200',borderRadius:10, paddingLeft:20,paddingRight:20,paddingTop:6,paddingBottom:6,border:'none',fontWeight:600,boxShadow:'0px 8px 15px grey'}}>
              Back
            </button>
        </div>
      </div></div>

      </section>
    </>
  );
};

export default PaymentStatus;
