import React, { useEffect } from "react";
import EducrackAPI from "@lipihipi/client-sdk";
import { useHistory, useLocation } from "react-router-dom";
import { setToken } from "./login";

const ByPassLoginPage = () => {
  const history = useHistory();

  const useQuery = () => new URLSearchParams(useLocation().search);
  let query = useQuery();
  const token = query.get("token") || "";

  useEffect(() => {
    if (token) {
      console.log(token);
      EducrackAPI.studentProfile
        .login({ token })
        .then(({ data }: any) => {
          console.log(data);
          setToken(data.token, history, null);
        })
        .catch((err) => {
          history.push("/login");
        });
    }
  }, []);

  return <></>;
};

export default ByPassLoginPage;
