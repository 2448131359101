import React from "react";
import EducrackAPI from "@lipihipi/client-sdk";
import {
  MyGoals,
  CourseSelection,
  EduPro,
  MyProgress,
  Notification,
  Profile,
} from "@lipihipi/ec-student-web";
import {
  TestBundle,
  TestBundleDetails,
  TestInstructions,
  Test,
  TestResultsNew,
  DsTestLandingPage,
  PsychometricTest,
} from "@lipihipi/ec-test-bundles";
import { useHistory, useLocation, useParams } from "react-router-dom";
// import Payment from '../payment/cashFree';
import Payment from "../payment/razorpay";
import AuthAPI from "../../api/auth";

const CommingSoon = () => {
  return (
    <div>
      <h1>This feature will be comming soon...</h1>
    </div>
  );
};

const UserHomePage = () => {
  return (
    <div>
      <h1>User Home Page component will be here</h1>
    </div>
  );
};

const MyGoalsPage = () => {
  return (
    <MyGoals
      getCourses={EducrackAPI.course.list}
      getMyProfile={EducrackAPI.user.me}
      updateStudentProfile={EducrackAPI.studentProfile.update}
    />
  );
};

const CourseSelectionPage = () => {
  return (
    <CourseSelection
      getCourses={(params: any) =>
        EducrackAPI.course.list({ ...params, ...{ all: true } })
      }
      getMyProfile={AuthAPI.getCacheUserMe}
      updateStudentProfile={EducrackAPI.studentProfile.update}
      // @ts-ignore
      imageBaseUrl={EducrackAPI.asset.getAssetUrl}
    />
  );
};

//  test bulde related pages for student
const StudentTestBundleList = (props: any) => {
  console.log(props.selectedCourse);
  const history = useHistory();
  return (
    <TestBundle
      breadCrumbs={[{ title: "Tests", link: "#" }]}
      getTestBundlelist={EducrackAPI.userTestBundle.list}
      fetchBundleList={EducrackAPI.testBundleV2.list}
      getAssetUrl={EducrackAPI.asset.getAssetUrl}
      onTestBundleClick={(bundleId: any) => {
        console.log("test>>", bundleId);
        history.push(`/course/tests/details/${bundleId}`);
      }}
      imageBaseUrl={EducrackAPI.asset.getAssetUrl()}
      selectedCourse={props.selectedCourse}
      onTestBundleDetailsClickToSub={(id: string) => {
        history.push(`/course/tests/sub/details/${id}`);
        console.log("onTestBundleDetailsClickToSub bundle", id);
      }}
    />
  );
};

const StudentTestBundleDetails = () => {
  const { id } = useParams();
  const history = useHistory();
  const user: any = AuthAPI.getCurrentUser();
  return (
    <TestBundleDetails
      getTestBundleDetails={EducrackAPI.userTestBundle.get} //new api
      getTestBundleDeatilsToSubscribe={EducrackAPI.testBundleV2.get}
      userTestEnrollment={EducrackAPI.userTest.create} //new api
      listTestAttempt={EducrackAPI.testAttempt.list} //new api
      onTestBundleSubscribe={EducrackAPI.userTestBundle.create}
      getAssetUrl={EducrackAPI.asset.getAssetUrl}
      role={user?.role}
      onTestClick={(testId: string) => {
        const _window = window as any;
        _window.open(`/course/test/instructions/${testId}`);
      }}
      onTestAttemptResultClick={(attemptId: string) => {
        const _window = window as any;
        _window.open(`/course/test/result/${attemptId}`);
      }}
      onTestInProgress={async (
        testId: string,
        isShinkan: boolean = false,
        shinkanProctorUrl: string
      ) => {
        if (isShinkan) {
          const { data } = await EducrackAPI.studentProfile.getLoginToken(
            user?._id
          );
          if (data?.token && shinkanProctorUrl) {
            window.location.href = `${shinkanProctorUrl}/?testid=${testId}&token=${data?.token}&domain=https://${window.location.host}`;
            return;
          }
        }
        const url = `/course/test/${testId}`;
        history.push(url);
      }}
      isSubsribed={true}
      onSubscribeSuccess={() => {
        console.log("after Subscribe  Success");
      }}
      bundleId={id}
      imageBaseUrl={EducrackAPI.asset.getAssetUrl()}
    />
  );
};

const StudentTestBundleDetailsToSub = () => {
  const { id } = useParams();
  const history = useHistory();
  const user: any = AuthAPI.getCurrentUser();

  return (
    <TestBundleDetails
      role={user?.role}
      user={user}
      getTestBundleDetails={EducrackAPI.userTestBundle.get} //new api
      getTestBundleDeatilsToSubscribe={EducrackAPI.testBundleV2.get}
      userTestEnrollment={EducrackAPI.userTest.create} //new api
      listTestAttempt={EducrackAPI.testAttempt.list} //new api
      onTestBundleSubscribe={EducrackAPI.userTestBundle.create}
      getAssetUrl={EducrackAPI.asset.getAssetUrl}
      initCashFreePayment={Payment.initCashFreePayment}
      onTestClick={(testId: string) => {
        const _window = window as any;
        _window.open(`/course/test/instructions/${testId}`);
      }}
      onTestAttemptResultClick={(attemptId: string) => {
        const _window = window as any;
        _window.open(`/course/test/result/${attemptId}`);
      }}
      onSubscribeSuccess={() => {
        console.log("after Subscribe  Success");
        history.push(`/course/tests`);
      }}
      onTestInProgress={async (
        testId: string,
        isShinkan: boolean = false,
        shinkanProctorUrl: string
      ) => {
        if (isShinkan) {
          const { data } = await EducrackAPI.studentProfile.getLoginToken(
            user?._id
          );
          if (data?.token && shinkanProctorUrl) {
            window.location.href = `${shinkanProctorUrl}/?testid=${testId}&token=${data?.token}&domain=https://${window.location.host}`;
            return;
          }
        }
        const url = `/course/test/${testId}`;
        history.push(url);
      }}
      isSubsribed={false}
      bundleId={id}
      imageBaseUrl={EducrackAPI.asset.getAssetUrl()}
    />
  );
};

const StudentTest = (props: any) => {
  const { id } = useParams();
  const history = useHistory();
  const user: any = AuthAPI.getCurrentUser();
  const { tenantId } = EducrackAPI.getConfig();
  const useQuery = () => new URLSearchParams(useLocation().search);
  let query = useQuery();
  const token = query.get("token") || "";

  return (
    <Test
      token={token}
      tenantId={tenantId}
      user={user}
      supportTicketCreate={EducrackAPI.support.create}
      getAssetUrl={EducrackAPI.asset.getAssetUrl}
      createTestAttempt={EducrackAPI.testAttempt.create}
      getTestAttempt={EducrackAPI.testAttempt.get}
      submitAnswer={EducrackAPI.testAttempt.submitAnswer}
      submitTestAttempt={EducrackAPI.testAttempt.submitAttempt}
      listTestAttempt={EducrackAPI.testAttempt.list}
      onTestSubmit={(attemptId: string) => {
        history.push(`/course/test/result/${attemptId}`);
      }}
      testId={id}
      imageBaseUrl={EducrackAPI.asset.getAssetUrl()}
      onCompletedOrTimeOut={() => {
        console.log("onSuccessAddEdit bundle");
        // history.push('/tests/bundles');
      }}
      userLogin={EducrackAPI.studentProfile.login}
      setToken={EducrackAPI.setToken}
      createAsset={EducrackAPI.asset.create}
    />
  );
};

const StudentTestInstructions = ({ isPsychometric }: any) => {
  const { id } = useParams();
  const history = useHistory();
  const user: any = AuthAPI.getCurrentUser();

  return (
    <TestInstructions
      getTest={EducrackAPI.userTest.get}
      testId={id}
      isPsychometric={isPsychometric}
      onTestContinueClick={async (
        testId: string,
        isShinkan: boolean = false,
        shinkanProctorUrl: string
      ) => {
        if (isShinkan) {
          const { data } = await EducrackAPI.studentProfile.getLoginToken(
            user?._id
          );
          if (data?.token && shinkanProctorUrl) {
            window.location.href = `${shinkanProctorUrl}/?testid=${testId}&token=${data?.token}&domain=https://${window.location.host}`;
            return;
          }
        }
        const url = isPsychometric
          ? `/psychometric-test/${testId}`
          : `/course/test/${testId}`;
        history.push(url);
      }}
      getAssetUrl={EducrackAPI.asset.getAssetUrl}
    />
  );
};

const DSStudentAttemptTest = () => {
  const { testId } = useParams();
  const { freeResourceId } = useParams();
  const history = useHistory();
  return (
    <DsTestLandingPage
      testId={testId}
      id={freeResourceId}
      userTestEnrollment={EducrackAPI.userTest.create}
      listTestAttempt={EducrackAPI.testAttempt.create}
      onSuccessEnroll={(enrolId: string) => {
        history.push(`/course/test/instructions/${enrolId}`);
      }}
      onFailEnroll={() => {
        history.push(`/course/wall`);
      }}
    />
  );
};

const StudentTestResult = () => {
  const { id } = useParams();
  const history = useHistory();
  const user: any = AuthAPI.getCurrentUser();

  return (
    <TestResultsNew
      user={user}
      getTestAttempt={EducrackAPI.testAttempt.get}
      imageBaseUrl={EducrackAPI.asset.getAssetUrl()}
      onTestInProgress={(testId: string) => {
        const _window = window as any;
        _window.open(`/course/test/${testId}`);
      }}
      onNoTestResultReady={() => {
        // console.log('No test / test result move to test/list page')
        history.push(`/course/tests`);
      }}
      attemptId={id}
      getAssetUrl={EducrackAPI.asset.getAssetUrl}
      questionAnalysisData={EducrackAPI.testAttempt.questionAlalysisData}
      topPerformer={EducrackAPI.testAttempt.topPerformer}
      otherAttempts={EducrackAPI.testAttempt.otherAttempts}
    />
  );
};

const UserEduPro = (props: any) => {
  const history = useHistory();
  return (
    <EduPro
      getTestBundlelist={EducrackAPI.userTestBundle.list}
      getSubscribedInterviews={EducrackAPI.userInterview.list}
      getPaymentHistory={EducrackAPI.payment.list}
      getAssetUrl={EducrackAPI.asset.getAssetUrl}
      imageBaseUrl={EducrackAPI.asset.getAssetUrl()}
      getPurchasedBatch={EducrackAPI.batchV2.listPurchasedBatches}
      onBatchClick={(_id: any) => {
        console.log("onBatchClick for details", _id);
        history.push(`/course/batch/${_id}`);
      }}
      onInterviewsClick={(interviewId: string) => {
        console.log("onInterviewsClick for details", interviewId);
        history.push(`/course/interviews/${interviewId}`);
      }}
      onTestBundleClick={(bundleId: string) => {
        console.log("onTestBundleClick for details", bundleId);
        history.push(`/course/tests/details/${bundleId}`);
      }}
    />
  );
};

const StudentProgress = (props: any) => {
  const history = useHistory();

  return (
    <MyProgress
      purchaseBatch={EducrackAPI.batchV2.listPurchasedBatches}
      testBundle={EducrackAPI.userTestBundle.list}
      getTestDetails={EducrackAPI.userTestBundle.get}
      allInterview={EducrackAPI.userInterview.list}
      getBatch={EducrackAPI.batchV2.get}
      onDetailClick={(_id: any, param: string) => {
        console.log("Details of:", _id, param);
        history.push(
          param ? `/course/batch/${_id}?tab=${param}` : `/course/batch/${_id}`
        );
        console.log(
          `https://dev-dashboard.educrack.com/course/batch/${_id}?tab=${param}`
        );
      }}
      onTestBundleClick={(_id: any) => {
        history.push(`/course/tests/details/${_id}`);
        console.log("Test_bundle:", _id);
        console.log(
          `https://dev-dashboard.educrack.com/course/tests/details/${_id}`
        );
      }}
      onInterviewClick={(_id: any) => {
        console.log("Interview:", _id);
        history.push(`/course/interviews/${_id}`);
        console.log(
          `https://dev-dashboard.educrack.com/course/interviews/${_id}`
        );
      }}
    />
  );
};

const manageRedirectionFromNotifcation = (notification: any, history: any) => {
  console.log("view Notification Details and redirect to module", notification);
  switch (notification.module) {
    case "WALL":
      history.push(`/course/wall#${notification.id}`);
      break;
    case "DOUBTS":
      history.push(`/course/doubt#${notification.id}`);
      break;
    case "TEST":
      history.push(`/course/tests/details/${notification.id}`);
      break;
    case "BATCH":
      history.push(`/course/batch/${notification.id}`);
      break;
    case "INTERVIEW":
      history.push(`/course/interviews/${notification.id}`);
      break;
    default:
      break;
  }
};

const UserNotifications = (props: any) => {
  const history = useHistory();
  return (
    <Notification
      imageBaseUrl={EducrackAPI.asset.getAssetUrl()}
      notifications={EducrackAPI.notifications.list}
      markReadNotifications={EducrackAPI.notifications.markRead}
      viewNotificationDetails={(notification: any) =>
        manageRedirectionFromNotifcation(notification, history)
      }
    />
  );
};

const user: any = AuthAPI.getCurrentUser();

const StudentProfile = (props: any) => {
  const history = useHistory();
  return (
    <Profile
      _id={user ? user._id : ""}
      breadCrumbs={[{ title: "Profile", link: "/my-profile" }]}
      getStudentProfile={EducrackAPI.user.me}
      uploadFile={EducrackAPI.asset.create}
      getAssetUrl={EducrackAPI.asset.getAssetUrl}
      onUpdate={EducrackAPI.studentProfile.update}
      getFollowDetails={EducrackAPI.follow.list}
      onFollowClick={(_id: any, params: any) => {
        console.log("StudentId & Tab", _id, params);
      }}
      upload={EducrackAPI.asset.createPrivate}
      getPrivateAsset={EducrackAPI.asset.getPrivateAssetUrl}
    />
  );
};

const UserPsychometricTest = () => {
  const { id } = useParams();
  return (
    <PsychometricTest
      user={user}
      testId={id}
      createTestAttempt={EducrackAPI.psychometricTestAttempt.create}
      getTestAttempt={EducrackAPI.psychometricTestAttempt.get}
      submitAnswer={EducrackAPI.psychometricTestAttempt.submitAnswer}
      submitTestAttempt={EducrackAPI.psychometricTestAttempt.submitAttempt}
      getAssetUrl={EducrackAPI.asset.getAssetUrl}
    />
  );
};

export {
  CommingSoon,
  UserHomePage,
  MyGoalsPage,
  CourseSelectionPage,
  StudentTestBundleList, // OK
  StudentTestBundleDetails,
  StudentTestBundleDetailsToSub,
  StudentTest,
  DSStudentAttemptTest,
  StudentTestInstructions, // OK
  StudentTestResult, // OK
  UserEduPro,
  StudentProgress,
  UserNotifications,
  StudentProfile,
  UserPsychometricTest,
};
