import React from "react";
import {
  StudentInterviewList,
  InterviewAttempts,
  StudentInterviewDetails,
  CreateResumes,
  ResumeListing
} from "@lipihipi/ec-interviews";
import EducrackAPI from "@lipihipi/client-sdk";
import { useParams, useHistory } from "react-router-dom";
// import Payment from '../payment/cashFree';
import Payment from "../payment/razorpay";
import RESUME_1 from "../../images/resume/1.png";
import RESUME_2 from "../../images/resume/2.png";
import RESUME_3 from "../../images/resume/3.png";
import RESUME_4 from "../../images/resume/4.png";

export const StudentManageInterviewList = (props: any) => {
  const history = useHistory();
  return (
    <StudentInterviewList
      getInterviews={EducrackAPI.interview.list}
      getSubscribedInterviews={EducrackAPI.userInterview.list}
      getAssetUrl={EducrackAPI.asset.getAssetUrl}
      selectedCourse={props.selectedCourse}
      onInterviewClick={(interviewId: string) => {
        history.push(`/course/interviews/details/${interviewId}`);
      }}
      onInterviewAttemptClick={(interviewId: string) => {
        history.push(`/course/interviews/${interviewId}`);
      }}
      assetsBaseUrl={EducrackAPI.asset.getAssetUrl()}
    />
  );
};
export const StudentInterviewDetailsToSubscribe = (props: any) => {
  const { id } = useParams();
  const history = useHistory();

  return (
    <StudentInterviewDetails
      getInterview={EducrackAPI.interview.get}
      getOtherInterviews={EducrackAPI.interview.list}
      onSubscribe={EducrackAPI.userInterview.subscribeInterview}
      selectedCourse={props.selectedCourse}
      initCashFreePayment={Payment.initCashFreePayment}
      getAssetUrl={EducrackAPI.asset.getAssetUrl}
      onInterviewClick={(interviewId: string) => {
        history.push(`/course/interviews/details/${interviewId}`);
      }}
      viewAllOptionsClick={(tabName:any) => {
        history.push(tabName?`/course/interviews?tabName=${tabName}`:`/course/interviews`);
      }}
      id={id}
    />
  );
};

export const StudentInterviewAttempts = () => {
  const { id } = useParams();
  const history = useHistory();
  return (
    <InterviewAttempts
      getInterview={EducrackAPI.userInterview.get}
      onAttemptInterview={EducrackAPI.userInterview.attend}
      getReport={EducrackAPI.userInterview.getReport}
      getVideo={EducrackAPI.userInterview.getVideo}
      getAssetUrl={EducrackAPI.asset.getAssetUrl}
      afterAttemptSuccess={(attemptRes: any) => {
        window.open(attemptRes.interview_url);
        window.location.reload();
      }}
      onResumeSubmitClick={() => {
        history.push(`/resume`);
      }}
      id={id}
    />
  );
};

export const StudentResumePreviews = () => {
  return (
    <div>
      <h1>This feature will be coming soon...</h1>
    </div>
  );
  // return <ResumePreviews
  //           myResume = {EducrackAPI.resume.me}
  //       />
};

export const StudentResumeUpdate = () => {
  const { id } = useParams();
  const history = useHistory();
  return (
    <CreateResumes
      id={""}
      description="Your Resume has to be complete to Attempt Interview in Mock Interview section on EduCrack app. You can create your resume, download and save for your use."
      myResume={EducrackAPI.resume.me}
      updateResume={EducrackAPI.resume.update}
      createResume={EducrackAPI.resume.create}
      getResumePDF={EducrackAPI.resume.getPDF}
      onPreview={() => {
        // window.open(`/resume-preview`);
      }}
      afterFinalSubmitSuccess={() => {
        // window.open('/resume-preview');
      }}
    />
  );
};
export const StudentResumeTemplateList = () => {
  return (
    <ResumeListing
      resumeThumbnail={{
        first: RESUME_1,
        second: RESUME_2,
        third: RESUME_3,
        fourth: RESUME_4
      }}
      title={'Resume Template'}
      breadCrumbs={ [{ title: 'Resume Template', link: '#' }]}
    />
  );
};
