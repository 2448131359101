import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Form, Input } from "@lipihipi/form";
import { LeftPanel } from "../common";
import EducrackAPI from "@lipihipi/client-sdk";

const LoginForm: FC<any> = (props: any) => {
  const { name, styles: siteStyles } = EducrackAPI.getConfig();
  const styles = siteStyles || {};
  return (
    <main className="user-structure">
      <LeftPanel
        imageBaseUrl={props.imageBaseUrl}
        title={styles?.onboardingText || ""}
      />
      <div className="user-structure--form">
        <div className="wrap">
          <h2>{`Login to ${name}`}</h2>
          <Form
            initialValues={{ mobile: props.mobile }}
            onSubmit={props.handleSubmit}
          >
            <Input
              id="mobile-number"
              name="mobile"
              type="tel"
              label="Mobile Number"
              maxLength={10}
              required={true}
            />
            {props.error && (
              <>
                <div className="text-danger">{props.error}</div>
                <br />
              </>
            )}
            {props?.user?.hasPassword && (
              <>
                <Input
                  id="password"
                  name="password"
                  type="password"
                  label="Password"
                  required={true}
                />
                <div className="d-flex align-items-center">
                  <Link to="/forgot-password">Forgot Password?</Link>
                </div>
              </>
            )}
            <div className="button-group">
              <button
                type="submit"
                className="btn btn-primary"
                style={{ width: "100%" }}
              >
                Submit
              </button>
            </div>
          </Form>
        </div>
      </div>
    </main>
  );
};
export default LoginForm;
