import React from "react";
import EducrackAPI from "@lipihipi/client-sdk";
import { OTPVerification } from "@lipihipi/ec-student-web";
import AuthAPI from "../../api/auth";

const OTPVerifyPage = () => {
  const handleAfterLogin = (token: string) => {
    localStorage.setItem("token", token);
    AuthAPI.isAuthenticated = true;
    EducrackAPI.setToken(token);
  };
  return (
    <OTPVerification
    //@ts-ignore
      imageBaseUrl={EducrackAPI.asset.getAssetUrl}
      sendOtpParameter={EducrackAPI.user.sendOTP}
      verifyMobileParameter={EducrackAPI.user.verifyMobile}
      setToken={handleAfterLogin}
    />
  );
};

export default OTPVerifyPage;
