import React, {
  useEffect,
  useState,
  Suspense,
  useReducer,
  createContext,
} from "react";
import {
  Route,
  Router,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { createBrowserHistory } from "history";
//import {DetectAppIcon} from '@lipihipi/ec-student-web';
import DetectAppIcon from "./components/detectAppIcon";
import "./app.scss";
import "@lipihipi/student-theme";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import routes from "./routes";
import AuthAPI from "./api/auth";
import Layout from "./components/layout/layout";
import { reducer } from "./Reducer";
import EducrackAPI from "@lipihipi/client-sdk";
import { Loader } from "@lipihipi/ec-ui";

const history = createBrowserHistory();
const Main = () => {
  useEffect(() => {
    const { styles: siteStyles, title } = EducrackAPI.getConfig();
    const styles = siteStyles || {};
    let link: any = document.querySelector("link[rel~='icon']");
    if (!link && styles?.favicon) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    if (styles?.favicon) {
      link.href = EducrackAPI.asset.getAssetUrl(styles.favicon);
    }
    document.title = title || "";
  }, []);

  const { styles: siteStyles } = EducrackAPI.getConfig();
  const styles = siteStyles || {};

  return (
    <Router history={history}>
      <Suspense
        fallback={
          <Loader imageUrl={EducrackAPI.asset.getAssetUrl(styles.siteLoader)} />
        }
      >
        <Switch>
          {routes.map((props) => {
            const { route, routeProps } = props;
            return (
              <Route key={route} path={route} {...routeProps}>
                {/* THis is to display download app pop-up on mobile */}
                <DetectAppIcon
                  imageBaseUrl={EducrackAPI.asset.getAssetUrl()}
                  playStoreIcon={"play_store.png"}
                  appStoreIcon={"app-store.png"}
                  playStoreLink="http://play.google.com/store/apps/details?id=com.truecaller&hl=en"
                  appStoreLink="http://itunes.apple.com/lb/app/truecaller-caller-id-number/id448142450?mt=8"
                />
                <CustomRender route={props} />
                <ToastContainer />
              </Route>
            );
          })}
        </Switch>
      </Suspense>
    </Router>
  );
};

const CustomRender = ({ route: routeOptions }: { route: any }) => {
  const {
    component: Component,
    layout,
    resource,
    props: componentProps,
    isProtected,
  } = routeOptions;
  const [isLoading, setLoading] = useState(true);
  const [selectedCourse, setSelectedCourse] = useState({});
  const loginRequired = isProtected && !AuthAPI.isLoggedIn();
  const history = useHistory();
  const location = useLocation();
  const handleRedirection = () => {
    if (location.pathname.indexOf("/course/wall") > -1) {
      console.log("WALL");
      history.push("/course/wall");
    }
    if (
      location.pathname.indexOf("/course/batch") > -1 ||
      location.pathname.indexOf("/course/mentors") > -1
    ) {
      console.log("BATCH");
      history.push("/course/batch");
    }
    if (location.pathname.indexOf("/course/doubt") > -1) {
      console.log("DOUBT");
      history.push("/course/doubt");
    }
    if (location.pathname.indexOf("/course/tests") > -1) {
      console.log("TEST");
      history.push("/course/tests");
    }
    if (location.pathname.indexOf("/course/interviews") > -1) {
      console.log("INTERVIEWS");
      history.push("/course/interviews");
    }
  };

  useEffect(() => {
    if (isProtected && AuthAPI.isLoggedIn() && !AuthAPI.getCurrentUser()) {
      AuthAPI.setCurrentUser()
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          const pathname = location.pathname
            ? "/login?url=" + location.pathname
            : "/login";
          history.push(pathname);
          // history.push('/logout');
        });
    } else {
      setLoading(false);
    }
  }, []);

  const onCourseSelect = (
    course: any,
    isCourseListEmpty?: boolean,
    isSelectFromDropdown?: boolean
  ) => {
    if (isCourseListEmpty || !course) {
      history.push("/course/selection");
    } else {
      setSelectedCourse(course);
      if (isSelectFromDropdown) {
        handleRedirection();
      }
    }
  };

  componentProps.selectedCourse = selectedCourse;

  const pathname = location.pathname
    ? "/login?url=" + location.pathname
    : "/login";
  if (loginRequired) {
    history.push(pathname);
  }
  return isLoading ? null : loginRequired ? (
    <></>
  ) : layout ? (
    <Layout onCourseSelect={onCourseSelect} selectedCourse={selectedCourse}>
      <Component {...componentProps} selectedCourse={selectedCourse} />
    </Layout>
  ) : (
    <Component {...componentProps} />
  );
};

const initialState = {
  state: {
    page_loading: true,
    activeMenu: "live",
  },
  dispatch: () => {},
};

export const AppContext = createContext<{
  state: any;
  dispatch: any;
}>(initialState);

function App() {
  // const [state, setState] = useState<any>();
  const [state, dispatch] = useReducer(reducer, initialState.state);
  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <Main />
    </AppContext.Provider>
  );
}
export default App;
