import React, { FC, useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import UserDropdown from "./UserDropdown";
import ButtonDropdown from "./ButtonDropdown";
import HOME_ICON from "../../svg/home.svg";
import BATCH_ICON from "../../svg/batch.svg";
import DOUBTS_ICON from "../../svg/doubt.svg";
import TEST_ICON from "../../svg/test.svg";
import LIVE_ICON from "../../svg/live.svg";
import { HiMenu } from "react-icons/hi";
import HOME_ICON_ACTIVE from "../../svg/home_active.svg";
import BATCH_ICON_ACTIVE from "../../svg/batch_active.svg";
import DOUBTS_ICON_ACTIVE from "../../svg/doubt_active.svg";
import TEST_ICON_ACTIVE from "../../svg/test_active.svg";
import LIVE_ICON_ACTIVE from "../../svg/live_active.svg";

import NOTIFICATION_ICON from "../../svg/notification.svg";
import { BiHomeAlt } from "react-icons/bi";
import { FaGraduationCap } from "react-icons/fa";
import { VscQuestion } from "react-icons/vsc";
import { GoTasklist } from "react-icons/go";
import moment from "moment";
import { ActionType } from "../../Reducer";
import { AppContext } from "../../App";
import EducrackAPI from "@lipihipi/client-sdk";
import { defaultModules } from "../sidebar/sidebar";

interface IAppBarUser {
  getCurrentUser: any;
  onCourseSelect: any;
  imageBaseUrl: string;
  notifications: any;
  markReadNotifications: any;
  viewAllNotifications: any;
  viewNotificationDetails: any;
  activeItem: any;
  setActiveItem: any;
  toggleDrawer: any;
}

const eventKey = {
  HOME: "home",
  BATCH: "batch",
  DOUBT: "doubt",
  TEST: "test",
  LIVE: "live",
};

const AppBarUser: FC<any> = ({
  getCurrentUser,
  onCourseSelect,
  imageBaseUrl,
  notifications,
  markReadNotifications,
  viewAllNotifications,
  viewNotificationDetails,
  toggleDrawer,
}: IAppBarUser) => {
  const { state, dispatch } = useContext<any>(AppContext);
  const [studentProfile, setStudentProfile] = useState<any>();
  const [notificationList, setNotificationList] = useState<any>({
    notifications: [],
    totalItems: 0,
  });
  const userPermissions = EducrackAPI.getConfig().modules;

  const [isOpen, setIsOpen] = useState<any>(false);
  const [selected, setSelected] = useState<string>("WALL");
  const location = useLocation();
  // const [activeItem, setActiveItem] = useState("home");
  const permissions = [...userPermissions, ...defaultModules];
  const topMenu = [
    {
      key: eventKey.HOME,
      to: "/course/wall",
      show: permissions.includes("WALL"),
      icon: HOME_ICON,
      active_icon: HOME_ICON_ACTIVE,
      title: "Notice Board",
    },

    {
      key: eventKey.BATCH,
      to: "/course/batch",
      show: permissions.includes("MANAGE_BATCH"),
      icon: BATCH_ICON,
      active_icon: BATCH_ICON_ACTIVE,
      title: "Programs",
      resource: "MANAGE_BATCH",
    },

    {
      key: eventKey.DOUBT,
      to: "/course/doubt",
      show: permissions.includes("DOUBTS"),
      icon: DOUBTS_ICON,
      active_icon: DOUBTS_ICON_ACTIVE,
      title: "Ask Doubts",
    },
    {
      key: eventKey.TEST,
      to: "/course/tests",
      show:
        permissions.includes("TEST_BUNDLES") || permissions.includes("TESTS"),
      icon: TEST_ICON,
      active_icon: TEST_ICON_ACTIVE,
      title: "Test Series",
      resource: "TEST_BUNDLES",
    },
    {
      key: eventKey.LIVE,
      to: "/course/remote-class",
      show: permissions.includes("LIVE_SESSIONS"),
      icon: LIVE_ICON,
      active_icon: LIVE_ICON_ACTIVE,
      title: "Live Classes",
      resource: "LIVE_SESSIONS",
    },
  ];

  const init = () => {
    if (location.pathname.indexOf("/course/wall") > -1) {
      setSelected("WALL");
    }
    if (location.pathname.indexOf("/course/batch") > -1) {
      setSelected("BATCH");
    }
    if (location.pathname.indexOf("/course/doubt") > -1) {
      setSelected("DOUBT");
    }
    if (location.pathname.indexOf("/course/tests") > -1) {
      setSelected("TEST");
    }
    if (location.pathname.indexOf("/course/remote-class") > -1) {
      setSelected("LIVE_CLASS");
    }
  };

  const handleMenuShow = function () {
    const sideMenu = document.getElementById("default-drawer");
    sideMenu?.classList.toggle("show");
  };

  useEffect(() => {
    getCurrentUser()
      .then(({ data }: any) => {
        // const { studentProfile } = user.data;
        setStudentProfile(data);
        if (!(data.selectedCourses && data.selectedCourses.length)) {
          onCourseSelect(data.selectedCourses, true);
        }
      })
      .catch(() => {});
    init();
    getNotifications();
  }, []);

  const getNotifications = () => {
    notifications({ poplate: true })
      .then(({ data }: any) => {
        setNotificationList(data);
      })
      .catch(() => {});
  };

  const readNotifications = (notification: any) => {
    if (notification.isRead) {
      setIsOpen(false);
      viewNotificationDetails(notification);
    } else {
      markReadNotifications(notification._id)
        .then(() => {
          setIsOpen(false);
          viewNotificationDetails(notification);
        })
        .catch(() => {});
    }
  };

  const handleMenuChange = (selectedKey: any, e: any) => {
    // setActiveItem(selectedKey);
    dispatch({
      type: ActionType.SET_TOP_MENU,
      payload: selectedKey,
    });
  };
  const { logo } = EducrackAPI.getConfig();

  return (
    <>
      {!location.pathname.includes("/course/batch/") ? (
        <nav className="student-nav">
          <div className="logo">
            <HiMenu onClick={toggleDrawer} />
            <Link to="/course/wall">
              <img
                src={EducrackAPI.asset.getAssetUrl(logo?.key)}
                alt="logo"
                width="120px"
              />
            </Link>
          </div>
          <Nav activeKey={state.activeMenu} onSelect={handleMenuChange}>
            {topMenu.map(
              (menu) =>
                menu.show && (
                  <Nav.Link
                    key={menu.key}
                    eventKey={menu.key}
                    as={Link}
                    to={menu.to}
                  >
                    {state.activeMenu === menu.key ? (
                      <img src={menu.active_icon} alt={menu.key} />
                    ) : (
                      <img src={menu.icon} alt={menu.key} />
                    )}
                    {menu.title}
                  </Nav.Link>
                )
            )}
          </Nav>

          <div className="student-nav_wrap">
            <div className="notification-wrapper">
              <div
                className="notification-toggle"
                onClick={() => {
                  if (!isOpen) {
                    getNotifications();
                  }
                  setIsOpen(!isOpen);
                }}
              >
                <img
                  src={NOTIFICATION_ICON}
                  alt={"notification"}
                  className={"noti-icon"}
                />
              </div>
              <div className={isOpen ? "menu-wrap active" : "menu-wrap "}>
                <h3>Notifications</h3>
                {/* {notificationList.notifications.length ?  */}
                {notificationList.totalItems ? (
                  <ul>
                    {notificationList.notifications.map((item: any) => {
                      return (
                        <li
                          key={item._id}
                          className={item.isRead ? "" : "active"}
                          onClick={() => {
                            readNotifications(item);
                          }}
                        >
                          <div className="wrap">
                            <strong>{item.event}</strong>
                            <span>
                              {moment(item.createdAt).format(
                                "DD MMMM YYYY h:mma"
                              )}
                            </span>
                          </div>
                          <p>{item.module}</p>
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  <p>No notification for You</p>
                )}

                {notificationList.notifications.length > 4 ? (
                  <div className="view-all" onClick={viewAllNotifications}>
                    View All
                  </div>
                ) : null}
              </div>
              {/* Add active class after notification menu onlick on notification icon */}
            </div>
            <UserDropdown profile={studentProfile} />
          </div>

          {/* <div className="left">
          <HiMenu onClick={handleMenuShow} />
          <div className="logo">
            <a href="#home">
              <img src={`${imageBaseUrl}logo.svg`} alt="logo" width="150px" />
            </a>
          </div>

          <Nav>
            <Nav.Link as={Link} to="/course/wall">
              Home
            </Nav.Link>
            <Nav.Link as={Link} to="/course/batch">
              Course
            </Nav.Link>
            <Nav.Link as={Link} to="/course/doubt">
              Doubt
            </Nav.Link>
            <Nav.Link as={Link} to="/course/tests">
              Test
            </Nav.Link>
            <Nav.Link as={Link} to="/course/remote-class">
              Live Classes
            </Nav.Link>
          </Nav>
        </div> */}

          {/* <div className="right">
          <ButtonDropdown
            list={studentProfile?.selectedCourses}
            onCourseSelect={onCourseSelect}
          />
          <div className="notification-wrapper">
            <div className="notification-toggle" onClick = {()=>{
              if(!isOpen) {
                getNotifications();
              }
              setIsOpen(!isOpen);
            }}>
              <img
                src={notificationicon}
                alt={'notification'}
                className={'noti-icon'}
              />
            </div>
            <div className={isOpen ? "menu-wrap active" : "menu-wrap "}>
              <h3>Notifications</h3>
              {notificationList.totalItems ? (
                <ul>
                  {notificationList.notifications.map((item: any) => {
                    return (
                      <li key={item._id} className={item.isRead ? '' : 'active'} onClick={()=>{readNotifications(item)}}>
                        <div className="wrap">
                          <strong>{item.event}</strong>
                          <span>{moment(item.createdAt).format('DD MMMM YYYY h:mma')}</span>
                        </div>
                        <p>{item.module}</p>
                      </li>
                    )
                  })}
                </ul>
              ) : <p>No notification for You</p>}

              {notificationList.notifications.length > 4 ?
                <div className="view-all" onClick ={viewAllNotifications}>View All</div> : null}
            </div>
          </div>
          <UserDropdown profile={studentProfile} />
        </div> */}
        </nav>
      ) : (
        ""
      )}
      <div className="d-lg-none course-dropdown-xs">
        <ButtonDropdown
          list={studentProfile?.selectedCourses}
          onCourseSelect={onCourseSelect}
        />

        <Nav className="mobile-menu-tabs" activeKey={state.activeMenu} onSelect={handleMenuChange}>
            {topMenu.map(
              (menu) =>
                menu.show && (
                  <Nav.Link
                    key={menu.key}
                    eventKey={menu.key}
                    as={Link}
                    to={menu.to}
                  >
                    {state.activeMenu === menu.key ? (
                      <img src={menu.active_icon} alt={menu.key} />
                    ) : (
                      <img src={menu.icon} alt={menu.key} />
                    )}
                    {menu.title}
                  </Nav.Link>
                )
            )}
          </Nav>



        {/* <Nav className="mobile-menu-tabs">
          <Nav.Link
            as={Link}
            to="/course/wall"
            className={selected === "WALL" ? "active" : ""}
          >
            <BiHomeAlt />
            Home
          </Nav.Link>
          <Nav.Link
            as={Link}
            to="/course/batch"
            className={selected === "BATCH" ? "active" : ""}
          >
            <FaGraduationCap />
            Course
          </Nav.Link>
          <Nav.Link
            as={Link}
            to="/course/doubt"
            className={selected === "DOUBT" ? "active" : ""}
          >
            <VscQuestion />
            Doubt
          </Nav.Link>
          <Nav.Link
            as={Link}
            to="/course/tests"
            className={selected === "TEST" ? "active" : ""}
          >
            <GoTasklist />
            Test
          </Nav.Link>
          <Nav.Link
            as={Link}
            to="/course/remote-class"
            className={selected === "LIVE_CLASS" ? "active" : ""}
          >
            Live Classes
          </Nav.Link>
        </Nav> */}
      </div>
    </>
  );
};

export default AppBarUser;
