import React, { FC } from 'react';
import { Form, Input } from '@lipihipi/form';
import POWERED from '../../../images/scholorship/powered.png';

const LoginForm: FC<any> = (props: any) => {

  return (
    <div className="user-structure--form">
      <div className="wrap">
        <h3 className='mb-3 mb-md-5'>Enter your Mobile number to proceed</h3>
        <Form
          initialValues={{ mobile: props.mobile}}
          onSubmit={props.handleSubmit}
        >
          <Input id="mobile-number" name="mobile" type="tel" label="Mobile Number" maxLength={10} required ={true}/>
          {props.error && (
            <>
              <div className="text-danger">{props.error}</div>
              <br />
            </>
          )}
          <div className="button-group">
            <button type="submit" className="btn btn-primary" style={{width: '100%'}}>
              Save & Proceed
            </button>
          </div>

          <div className='d-flex d-md-none justify-content-center mt-5'>
            <img src={POWERED} alt="POWERED" />
          </div>
        </Form>
      </div>
    </div>
  );
};
export default LoginForm;
