import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import AuthAPI from "../../api/auth";
import EducrackAPI from "@lipihipi/client-sdk";
import { useLocation } from "react-router-dom";
import NotificationService from "../../api/notificationService";
import LoginForm from "./LoginForm";
import { ILoginParams } from "@lipihipi/client-sdk/dist/auth";

export const setToken = (
  token: string,
  history: any,
  redirectUrl: string | null
) => {
  localStorage.setItem("token", token);

  AuthAPI.isAuthenticated = true;
  EducrackAPI.setToken(token);
  AuthAPI.setCurrentUser().then((currentUser: any) => {
    setTimeout(() => {
      NotificationService.show(
        "Happy to see you, Welcome " + currentUser?.name
      );
    }, 200);

    if (AuthAPI.isLoggedIn()) {
      history.push(
        redirectUrl || AuthAPI.getRedirectUrl(AuthAPI.getCurrentUser())
      );
    }
  });
};

const LoginPage = () => {
  // const _window = window as any;

  const history = useHistory();

  const useQuery = () => new URLSearchParams(useLocation().search);
  let query = useQuery();
  // const absoluteUrl = window.location.href
  let redirectUrl:any=''
  // if(absoluteUrl.includes('https://student.cat4me.com/')){
    redirectUrl = query.get("url") || "/course/wall";
  // }else{
  //   redirectUrl = query.get("url") || "/dashboard";

  // }
  AuthAPI.logout();

  const [error, setError] = useState<any>();
  const [user, setUser] = useState();
  const encodedString = query.get("mobile");

  let mobile: any = null;
  if (encodedString) {
    mobile = atob(encodedString);
  }

  React.useEffect(() => {
    if (mobile) {
      handleSubmit({ mobile: mobile });
    }
  }, [mobile]);

  const handleSubmit = async (values: ILoginParams) => {
    try {
      let response;
      response = await EducrackAPI.auth.login(values);

      if (response.data?.message === "Invalid Credentials") {
        const message = "Invalid Mobile number.";
        setError(message);
        return;
      }
      setError("");
      if (!response.data.user.hasPassword) {
        const user = {
          id: response.data.user._id,
          mobile: response.data.user.mobile,
        };
        localStorage.setItem("user", JSON.stringify(user));
        history.push("/otp-verify");
      } else if (response.data.token) {
        setToken(response.data.token, history, redirectUrl);
      } else {
        setUser(response.data.user);
      }
    } catch (error) {
      setError(error.data.message);
    }
  };

  return (
    <LoginForm
      handleSubmit={handleSubmit}
      error={error}
      user={user}
      mobile={mobile || ""}
      imageBaseUrl={EducrackAPI.asset.getAssetUrl}
    />
  );
};

export default LoginPage;
