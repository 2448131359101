import React from "react";
import EducrackAPI from "@lipihipi/client-sdk";
import { CreatePassword } from "@lipihipi/ec-student-web";

const CreatePasswordPage = () => {
  return (
    <CreatePassword
      createPassword={EducrackAPI.user.setPassword}
      // @ts-ignore
      imageBaseUrl={EducrackAPI.asset.getAssetUrl}
    />
  );
};

export default CreatePasswordPage;
