import React, { useEffect, useState } from 'react';
import { Modal } from '@lipihipi/ec-ui';
import { Button } from '@lipihipi/ec-ui';

declare global {
  interface Window {
    opera: any;
  }
}
window.opera = window.opera || {};

const constant = {
  WINDOWS_PHONE: 'WINDOWS_PHONE',
  ANDROID: 'ANDROID',
  IOS: 'IOS',
  UNKNOWN: 'UNKNOWN',
};

const DetectAppIcon = ({
  imageBaseUrl,
  playStoreIcon,
  appStoreIcon,
  playStoreLink,
  appStoreLink,
}: any) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [deviceName, setDeviceName] = useState<string>(constant.UNKNOWN);

  useEffect(() => {
    const _deviceName = getMobileOperatingSystem();
    setDeviceName(_deviceName);
  }, []);

  const handleOpen = (value: boolean) => {
    setOpenModal(value);
  };

  const getMobileOperatingSystem = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // if (/windows phone/i.test(userAgent)) {
    //   return constant.WINDOWS_PHONE;
    // }

    if (/android/i.test(userAgent)) {
      return constant.ANDROID;
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return constant.IOS;
    }

    return constant.UNKNOWN;
  };

  const redirectAppIcons = (link: string) => {
    const links: any = {
      ANDROID: playStoreLink,
      IOS: appStoreLink,
    };
    const url = links[link];

    window.open(url, '_blank');
  };

  if (deviceName === constant.UNKNOWN) {
    return null;
  }

  return (
    <Modal
      isOpen={openModal}
      heightDefault={true}
      onRequestClose={() => handleOpen(false)}
    >
      <div>
        <div className="primary-page-header mb-3" style={{ width: 400 }}>
          <div className="wrap">
            <h3 className="text-center">
              Please download Mobile app for better experience
            </h3>
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-center">
          {deviceName === constant.ANDROID ? (
            <Button
              size={'large'}
              className="m-3 p-1"
              shape="secondary"
              onClick={() => redirectAppIcons(constant.ANDROID)}
            >
              <img
                height="100%"
                src={`${imageBaseUrl}${playStoreIcon}`}
                alt={constant.ANDROID}
              />
            </Button>
          ) : (
            ''
          )}

          {deviceName === constant.IOS ? (
            <Button
              size={'large'}
              className="m-3 p-1"
              shape="secondary"
              onClick={() => redirectAppIcons(constant.IOS)}
            >
              <img
                height="100%"
                src={`${imageBaseUrl}${appStoreIcon}`}
                alt={constant.IOS}
              />
            </Button>
          ) : (
            ''
          )}
        </div>

        <div className="text-center">
          <Button
            size={'large'}
            className="mt-3 px-3 mx-auto"
            shape="primary"
            onClick={() => handleOpen(false)}
          >
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DetectAppIcon;
