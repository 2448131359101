import EducrackAPI from '@lipihipi/client-sdk';
import swal from 'sweetalert';

interface IpaymentPros {
  productId: string;
  productType: string;
  coupon: string;
  userName: string;
  userMobile: string;
  userEmail: string;
  productName: string;
}

async function InitPayment(paymentData: IpaymentPros) {

  const paymentSuccessHandler = (data: any) => {
    const _window = window as any;
    console.log('paymentSuccessHandler', data);
    let redirectUrl = _window.location.origin;
    switch (paymentData.productType) {
      case 'TESTBUNDLE':
        redirectUrl += '/course/tests';
        break;
      case 'INTERVIEW':
        redirectUrl += '/course/interviews';
        break;
      case 'BATCH':
        redirectUrl += '/course/batch';
        break;
      case 'PROGRAM':
        redirectUrl += '/course/batch';
        break;
      default:
        redirectUrl += '/dashboard';
        break;
    }
    console.log('redirectUrl', redirectUrl);
    swal({
      title: 'Success',
      text: 'Payment Success',
      icon: 'success',
      dangerMode: false,
    }).then(() => {
      _window.location.href = redirectUrl;
    });
  }

  const paymentErrorHandler = (errorData: any) => {
    console.log('paymentErrorHandler', errorData);
  }


  const payload = {
    id: paymentData.productId,
    type: paymentData.productType,
    coupon: paymentData.coupon
  };
  console.log('hehehhehehe', payload);



  // This payment API need SDK version 134+
  const { data }: any = await EducrackAPI.payment.createOrder(payload);
  //   const data = {
  //     "key" : 'rzp_test_bDlBQ5G4ZLujjY',
  //     "id": "order_GkD0yBERjMVGln",
  //     "entity": "order",
  //     "amount": 200000,
  //     "amount_paid": 0,
  //     "amount_due": 200000,
  //     "currency": "INR",
  //     "receipt": "545458745adfasd",
  //     "offer_id": null,
  //     "status": "created",
  //     "attempts": 0,
  //     "notes": [],
  //     "created_at": 1615132033
  // }
  if (Object.keys(data).length === 0) {
    paymentSuccessHandler(data);
  }
  else {
    const options = {
      key: data.key,
      amount: data.amount,
      currency: data.currency,
      name: paymentData.productName,
      // description: paymentData.productDescription || 'Your Purchase',
      // image: paymentData.productImageUrl  || 'https://homepages.cae.wisc.edu/~ece533/images/pool.png',
      order_id: data.id,
      handler: paymentSuccessHandler,
      prefill: {
        "name": paymentData?.userName,
        "email": paymentData?.userEmail,
        "contact": paymentData?.userMobile
      },
    };

    const _window = window as any;
    const razorpayObject = new _window.Razorpay(options);
    razorpayObject.open();
    razorpayObject.on('payment.failed', paymentErrorHandler);
  }

}


const Payment = {
  initCashFreePayment: InitPayment
}


export default Payment;