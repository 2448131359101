import React from "react";
// import {BatchSection, LiveSession, LiveSessionStudent} from "@lipihipi/ec-batch";
import {
  // ManageBatches,
  // CreateBatch,
  //CreateBatchRecorded,
  BatchPreview,
  // Educator
  // EduManageBatches,
  EduBatchDetails,
  EduFreeResourceDetails,
  // Student
  StudentBatchList,
  StudentBatchDetail,
  StudentMentorDetail,
  StudentMentorList,
  StudentProgramDetail,
} from "@lipihipi/ec-batch";
import EduCrackAPI from "@lipihipi/client-sdk";
import { useHistory, useParams } from "react-router-dom";
// import Payment from '../payment/cashFree';
import Payment from "../payment/razorpay";

import AuthAPI from "../../api/auth";

// export const BatchListPage = () => {
//   const history = useHistory();
//   const user: any = AuthAPI.getCurrentUser();
//   console.log(user);
//   return (
//     <ManageBatches
//       user={user}
//       title={"Manage Batches"}
//       breadCrumbs={[{ title: "Manage Batches", link: "#" }]}
//       getAssetUrl={EduCrackAPI.asset.getAssetUrl}
//       getCourses={EduCrackAPI.course.list}
//       getBatch={EduCrackAPI.batchV2.list}
//       //createBatch={EduCrackAPI.batchV2.create}
//       publishBatch={EduCrackAPI.batchV2.publish}
//       deleteBatch={EduCrackAPI.batchV2.remove}
//       onCreateBatch={(value: any) => {
//         history.push(`/batch/create/${value}`);
//         console.log("on Create Batch: ", value);
//       }}
//       onEdit={(_id: any, isCreator: boolean) => {
//         console.log("on Edit Batch: ", _id, isCreator);
//         if (isCreator) {
//           history.push(`/batch/${_id}`);
//         } else {
//           history.push(`/batch/edu/${_id}`);
//         }
//       }}
//       onPreview={(value: any) => {
//         console.log("on Preview Batch: ", value);
//         history.push(`/batch/preview/${value}`);
//       }}
//     />
//   );
// };

// export const BatchCreate = () => {
//   const history = useHistory();
//   const { batchType } = useParams();
//   const id = null;
//   const user: any = AuthAPI.getCurrentUser();
//   return (
//     <CreateBatch
//       _id={id}
//       user={user}
//       title={"Create Batch"}
//       breadCrumbs={[
//         { title: "Manage Batches", link: "/batch" },
//         { title: id ? "Edit Batch" : "Create Batch", link: "#" },
//       ]}
//       batchType={batchType}
//       createBatch={EduCrackAPI.batchV2.create}
//       upload={EduCrackAPI.asset.create}
//       deleteAsset={EduCrackAPI.asset.remove}
//       getCourses={EduCrackAPI.course.list}
//       getExamList={EduCrackAPI.exam.list}
//       //getSectionSubjectsList={EduCrackAPI.section.list}
//       //getTopicList={EduCrackAPI.topic.list}
//       getEducatorList={EduCrackAPI.educator.list}
//       getTests={EduCrackAPI.questionSet.list}
//       getSectionSubjects={EduCrackAPI.section.getSectionSubjects}
//       getAssetUrl={EduCrackAPI.asset.getAssetUrl}
//       createAsset={EduCrackAPI.asset.create}
//       getLibrary={EduCrackAPI.courseware.list}
//       getBatch={EduCrackAPI.batchV2.get}
//       // updateBasicDetails={EduCrackAPI.batchV2.updateBasicDetails}
//       // updateSubjects={EduCrackAPI.batchV2.updateSubjects}
//       // removeSubject={EduCrackAPI.batchV2.removeSubject}
//       // addTopics={EduCrackAPI.batchV2.addTopics}
//       // removeTopics={EduCrackAPI.batchV2.removeTopics}
//       // assignTeacher={EduCrackAPI.batchV2.assignTeacher}
//       // removeTeacher={EduCrackAPI.batchV2.removeTeacher}
//       // addTest={EduCrackAPI.batchV2.addTest}
//       // updateTest={EduCrackAPI.batchV2.updateTest}
//       // removeTest={EduCrackAPI.batchV2.removeTest}
//       // addFreeSrc={EduCrackAPI.batchV2.addFreeSrc}
//       // removeFreeSrc={EduCrackAPI.batchV2.removeFreeSrc}
//       // addNotes={EduCrackAPI.batchV2.addNotes}
//       // removeNote={EduCrackAPI.batchV2.removeNote}
//       // addQuiz={EduCrackAPI.batchV2.addQuiz}
//       // removeQuiz={EduCrackAPI.batchV2.removeQuiz}
//       // teacherPublish={EduCrackAPI.batchV2.teacherPublish}
//       // updateNote={EduCrackAPI.batchV2.updateNote}
//       // updateQuiz={EduCrackAPI.batchV2.updateQuiz}
//       // addSession={EduCrackAPI.batchV2.addSession}
//       // updateSession={EduCrackAPI.batchV2.updateSession}
//       // removeSession={EduCrackAPI.batchV2.removeSession}
//       onPreviewResource={(value: any) => {
//         console.log("on Preview Resource: ", value);
//         history.push(`/batch/resource/preview/${value}`);
//       }}
//       onAddEdit={() => {
//         history.push(`/batch`);
//         console.log("final submit");
//       }}
//       onPreview={(value: any) => {
//         console.log("on Preview Batch: ", value);
//         history.push(`/batch/preview/${value}`);
//       }}
//       onCreateBatch={(value: any) => {
//         history.push(`/batch/${value}`);
//       }}
//     />
//   );
// };

// export const BatchEdit = () => {
//   const history = useHistory();
//   const { id } = useParams();
//   const user: any = AuthAPI.getCurrentUser();
//   return (
//     <CreateBatch
//       _id={id}
//       user={user}
//       title={id ? "Edit batch" : "Create Batch"}
//       breadCrumbs={[
//         { title: "Manage Batches", link: "/batch" },
//         { title: id ? "Edit Batch" : "Create Batch", link: "#" },
//       ]}
//       batchType={"unknown"}
//       createBatch={EduCrackAPI.batchV2.create}
//       upload={EduCrackAPI.asset.create}
//       deleteAsset={EduCrackAPI.asset.remove}
//       getCourses={EduCrackAPI.course.list}
//       getExamList={EduCrackAPI.exam.list}
//       //getSectionSubjectsList={EduCrackAPI.section.list}
//       //getTopicList={EduCrackAPI.topic.list}
//       getEducatorList={EduCrackAPI.educator.list}
//       getTests={EduCrackAPI.questionSet.list}
//       getSectionSubjects={EduCrackAPI.section.getSectionSubjects}
//       getAssetUrl={EduCrackAPI.asset.getAssetUrl}
//       createAsset={EduCrackAPI.asset.create}
//       getLibrary={EduCrackAPI.courseware.list}
//       getBatch={EduCrackAPI.batchV2.get}
//       // updateBasicDetails={EduCrackAPI.batchV2.updateBasicDetails}
//       // updateSubjects={EduCrackAPI.batchV2.updateSubjects}
//       // removeSubject={EduCrackAPI.batchV2.removeSubject}
//       // addTopics={EduCrackAPI.batchV2.addTopics}
//       // removeTopics={EduCrackAPI.batchV2.removeTopics}
//       // assignTeacher={EduCrackAPI.batchV2.assignTeacher}
//       // removeTeacher={EduCrackAPI.batchV2.removeTeacher}
//       // addTest={EduCrackAPI.batchV2.addTest}
//       // updateTest={EduCrackAPI.batchV2.updateTest}
//       // removeTest={EduCrackAPI.batchV2.removeTest}
//       // addFreeSrc={EduCrackAPI.batchV2.addFreeSrc}
//       // removeFreeSrc={EduCrackAPI.batchV2.removeFreeSrc}
//       // addNotes={EduCrackAPI.batchV2.addNotes}
//       // removeNote={EduCrackAPI.batchV2.removeNote}
//       // addQuiz={EduCrackAPI.batchV2.addQuiz}
//       // removeQuiz={EduCrackAPI.batchV2.removeQuiz}
//       // teacherPublish={EduCrackAPI.batchV2.teacherPublish}
//       // updateNote={EduCrackAPI.batchV2.updateNote}
//       // updateQuiz={EduCrackAPI.batchV2.updateQuiz}
//       // addSession={EduCrackAPI.batchV2.addSession}
//       // updateSession={EduCrackAPI.batchV2.updateSession}
//       // removeSession={EduCrackAPI.batchV2.removeSession}
//       onPreviewResource={(value: any) => {
//         console.log("on Preview Resource: ", value);
//         history.push(`/batch/resource/preview/${value}`);
//       }}
//       onAddEdit={() => {
//         history.push(`/batch`);
//         console.log("final submit");
//       }}
//       onPreview={(value: any) => {
//         console.log("on Preview Batch: ", value);
//         history.push(`/batch/preview/${value}`);
//       }}
//       onCreateBatch={(value: any) => {
//         console.log("It should not be triggered here");
//         //history.push(`/batch/${value}`);
//       }}
//     />
//   );
// };

// export const EduBatchEdit = () => {
//   const history = useHistory();
//   const { id } = useParams();
//   const user: any = AuthAPI.getCurrentUser();

//   return (
//     <EduBatchDetails
//       title={""}
//       breadCrumbs={[]}
//       educatorId={user?._id}
//       batchId={id}
//       getAssetUrl={EduCrackAPI.asset.getAssetUrl}
//       getSectionSubjects={EduCrackAPI.section.getSectionSubjects}
//       getLibrary={EduCrackAPI.courseware.list}
//       getTests={EduCrackAPI.questionSet.list}
//       getBatch={EduCrackAPI.batchV2.get}
//       // addNotes={EduCrackAPI.batchV2.addNotes}
//       // removeNote={EduCrackAPI.batchV2.removeNote}
//       // addQuiz={EduCrackAPI.batchV2.addQuiz}
//       // removeQuiz={EduCrackAPI.batchV2.removeQuiz}
//       // teacherPublish={EduCrackAPI.batchV2.teacherPublish}
//       // updateNote={EduCrackAPI.batchV2.updateNote}
//       // updateQuiz={EduCrackAPI.batchV2.updateQuiz}
//       onAddEdit={() => {
//         history.push(`/batch`);
//         console.log("redirect");
//       }}
//       onPreviewResource={(value: string) => {
//         history.push(`/batch/resource/preview/${value}`);
//         console.log("redirect");
//       }}
//       onPreview={(value: any) => {
//         console.log("on Preview Batch: ", value);
//         history.push(`/batch/preview/${value}`);
//       }}

//     //     title: any;
//     // breadCrumbs: any;
//     // educatorId: any;
//     // batchId: any;
//     // getAssetUrl: any;
//     // getSectionSubjects: any;
//     // getLibrary: any;
//     // getTests: any;
//     // getBatch: any;
//     // addNotes: any;
//     // removeNote: any;
//     // addQuiz: any;
//     // removeQuiz: any;
//     // updateNote: any;
//     // updateQuiz: any;
//     // teacherPublish: any;
//     // onAddEdit: any;
//     // onPreviewResource: any;
//     />
//   );
// };

export const PreviewBatch = () => {
  const history = useHistory();
  const { id } = useParams();
  const user: any = AuthAPI.getCurrentUser();
  return (
    <BatchPreview
      title={"Preview Batch"}
      user={user}
      breadCrumbs={[
        { title: "Manage Batches", link: "/batch" },
        { title: "Preview Batch", link: "#" },
      ]}
      batchId={id}
      getAssetUrl={EduCrackAPI.asset.getAssetUrl}
      getSectionSubjects={EduCrackAPI.section.getSectionSubjects}
      getLibrary={EduCrackAPI.courseware.list}
      // getTests={EduCrackAPI.questionSet.list}
      getBatch={EduCrackAPI.batchV2.get}
      onMentorClick={(_id: any) => {
        console.log(" mentor_id: ", _id);
        history.push(`/course/mentorDetails/${_id}`);
      }}
      onResourceClick={(_id: any) => {
        console.log(" resource_id: ", _id);
        history.push(`/batch/resource/preview/${_id}`);
      }}
      onEdit={(_id: any, isCreator: boolean) => {
        if (isCreator) {
          history.push(`/batch/${_id}`);
        } else {
          history.push(`/batch/edu/${_id}`);
        }
      }}
    />
  );
};

export const EduPreviewFreeResource = () => {
  const { id } = useParams();
  return (
    <EduFreeResourceDetails
      resourceId={id}
      getAssetUrl={EduCrackAPI.asset.getAssetUrl}
      getresource={EduCrackAPI.courseware.get}
    />
  );
};

export const StuBatchListPage = (props: any) => {
  const history = useHistory();
  return (
    <StudentBatchList
      title={"Learn from the best, be prepared, and crack it now!"}
      breadCrumbs={[{ title: "Student Programs", link: "/" }]}
      getAssetUrl={EduCrackAPI.asset.getAssetUrl}
      getProgram={EduCrackAPI.program.list}
      courseId={props.selectedCourse?._id}
      // searchMentor={EduCrackAPI.educator.search}
      // getSubjects={EduCrackAPI.subject.list}
      getPurchasedBatches={EduCrackAPI.batchV2.listPurchasedBatches}
      onBatchIdClick={(_id: any) => {
        console.log(" batch_id: ", _id);
        history.push(`/course/batch/${_id}`);
      }}
      onProgramIdClick={(_id: any) => {
        console.log(" batch_id: ", _id);
        history.push(`/course/program/${_id}`);
      }}
      // onMentorIdClick={(_id: any) => {
      //   console.log(" mentor_id: ", _id);
      //   history.push(`/course/mentorDetails/${_id}`);
      // }}
    />
  );
};

export const StuBatchDetailsPage = (props: any) => {
  const { id } = useParams();
  const history = useHistory();
  const user: any = AuthAPI.getCurrentUser();

  return (
    <StudentBatchDetail
      _id={id}
      role={user?.role}
      // breadCrumbs={[
      //   { title: "Batch", link: "/course/batch" },
      //   { title: "Batch Details" },
      // ]}
      courseBatch={EduCrackAPI.batchV2.get}
      pdfDownload={EduCrackAPI.batchV2.getPDFDownload}
      getAssetUrl={EduCrackAPI.asset.getAssetUrl}
      getTestBundle={EduCrackAPI.testBundleV2.get}
      // getLibrary={EduCrackAPI.courseware.list}
      batchBuyNow={EduCrackAPI.batchV2.purchaseBatch}
      initCashFreePayment={Payment.initCashFreePayment}
      getPurchasedBatches={EduCrackAPI.batchV2.listPurchasedBatches}
      userTestEnrollment={EduCrackAPI.userTest.create}
      userTestAttempt={EduCrackAPI.userTest.list}
      addBatchProgress={EduCrackAPI.batchV2.addBatchProgress}
      onTestClick={(id: any) => {
        const _window = window as any;
        _window.open(`/course/test/instructions/${id}`);
        console.log("onTestClick", id);
      }}
      onResourceClick={(_id: any) => {
        console.log(" resource_id: ", _id);
        history.push(`/course/batch/${id}/resource/${_id}`);
      }}
      onMentorClick={(_id: any) => {
        console.log(" mentor_id: ", _id);
        history.push(`/course/mentorDetails/${_id}`);
      }}
      onTestAttemptResultClick={(id: string) => {
        console.log("onTest Result  attempt", id);
        const _window = window as any;
        _window.open(`/course/test/result/${id}`);
      }}
      user={user}
    />
  );
};

export const StuProgramDetailsPage = (props: any) => {
  const { id } = useParams();
  const history = useHistory();
  const user: any = AuthAPI.getCurrentUser();

  return (
    <StudentProgramDetail
      _id={id}
      role={user?.role}
      // breadCrumbs={[
      //   { title: "Batch", link: "/course/batch" },
      //   { title: "Batch Details" },
      // ]}
      getBatch={EduCrackAPI.batchV2.list}
      getProgram={EduCrackAPI.program.get}
      getAssetUrl={EduCrackAPI.asset.getAssetUrl}
      batchBuyNow={EduCrackAPI.batchV2.purchaseBatch}
      initCashFreePayment={Payment.initCashFreePayment}
      user={user}
    />
  );
};

export const StuMentorDetailsPage = (props: any) => {
  const { id } = useParams();
  const history = useHistory();
  return (
    <StudentMentorDetail
      mentorId={id}
      getEducatorDetails={EduCrackAPI.educator.get}
      getAssetUrl={EduCrackAPI.asset.getAssetUrl}
    />
  );
};

export const StuMentorListPage = (props: any) => {
  const history = useHistory();
  return (
    <StudentMentorList
      title={"Mentors"}
      breadCrumbs={[{ title: "Mentors", link: "/" }]}
      getAssetUrl={EduCrackAPI.asset.getAssetUrl}
      courseId={props.selectedCourse?._id}
      searchMentor={EduCrackAPI.educator.list}
      getCourse={EduCrackAPI.course.list}
      getSubjects={EduCrackAPI.subject.list}
      onMentorIdClick={(_id: any) => {
        console.log(" mentor_id: ", _id);
        history.push(`/course/mentorDetails/${_id}`);
      }}
    />
  );
};
