import React from 'react';
import Styled from 'styled-components';
import { CircularProgressProps } from './circular-progress-types';
import { CircularProgressbar } from 'react-circular-progressbar';

const ProgressContainer = Styled.div`
  width: 110px;
  height: 110px;
  // margin-top:-10.5rem;
  // margin-left:5rem
`;

const Title = Styled.div`
  font-weight: 700;
  font-size: 26.149px;
  line-height: 129%;
  text-transform: capitalize;
  color: #242626;
`;

const ProgressText = Styled.span`
  font-weight: 700;
  font-size: 26.149px;
  line-height: 129%;
  text-transform: capitalize;
  color: #242626;
`;

const PercentageTextColor = Styled.span`
  font-weight: 700;
  font-size: 26.149px;
  line-height: 129%;
  text-transform: capitalize;
  color: #242626;
`;

const TotalProgressText = Styled.span`
  font-weight: 700;
  font-size: 26.149px;
  line-height: 129%;
  text-transform: capitalize;
  color: #242626;
`;

const ProgressTextContainer = Styled.span`
position: absolute;
width: 100%;
text-align: center;
margin-top: 0;
height: 100%;
top: 0;
display: flex;
align-items: center;
justify-content: center;
font-weight: 700;
font-size: 26.149px;
line-height: 129%;
text-transform: capitalize;
color: #242626;
`;

export const CircularProgress = ({
  progressValue,
  totalProgress,
  primaryColor,
  title,
  className,
  stroke,
  percentage,
  ...rest
}: CircularProgressProps) => {
  return (
    <React.Fragment>
      <div className="position-relative">
        <ProgressContainer className={className ? className : 'position-relative'} {...rest}>
          <CircularProgressbar
            counterClockwise={true}
            strokeWidth={10}
            maxValue = {totalProgress}
            styles={{
              path: {
                stroke: stroke ? stroke : primaryColor,
              },
              trail: {
                stroke: '#D9D9D9',
              },
            }}
            value={progressValue}
            // text={`${progressValue}/${totalProgress ? totalProgress : 100}`}
          />

          {percentage ? (
            <ProgressTextContainer>
              <PercentageTextColor>
                {`${progressValue}%`}
              </PercentageTextColor>
            </ProgressTextContainer>
          ) : (
            <>
              <ProgressTextContainer>
                <ProgressText style={{ color: primaryColor }}>
                  {progressValue}
                </ProgressText>
                /
                <TotalProgressText>
                  {totalProgress ? totalProgress : 100}
                </TotalProgressText>
              </ProgressTextContainer>
            </>
          )}
        </ProgressContainer>




        {title ? <Title style={{ color: primaryColor }}>{title}</Title> : null}
      </div>
    </React.Fragment>
  );
};
