import React from "react";
import EducrackAPI from "@lipihipi/client-sdk";
import { useHistory, useParams } from "react-router-dom";
import {
  StudentResource,
  StudentResourceDetail,
} from "@lipihipi/ec-freeresource";

export const FreeResourceList = (props: any) => {
  const history = useHistory();
  return (
    <StudentResource
      breadCrumbs={[{ title: "Free Resource", link: "/free-resource" }]}
      getResourceList={EducrackAPI.freeResource.list}
      getAssetUrl={EducrackAPI.asset.getAssetUrl}
      courseId={props.selectedCourse._id}
      imageBaseUrl={EducrackAPI.asset.getAssetUrl()}
      onVideoClick={(_id: any) => {
        history.push(`/free-resource/${_id}`);
      }}
      onTestClick={(_id: any, testId: any) => {
        window.open(`/free-resource/test/${_id}/${testId}`, "_blank");
      }}
    />
  );
};

export const FreeResourceDetails = (props: any) => {
  const { id }: any = useParams();
  //console.log(id, props.selectedCourse._id);
  return (
    <StudentResourceDetail
      breadCrumbs={[{ title: "Free Resource", link: "/free-resource" }]}
      getResource={EducrackAPI.freeResource.get}
      getAssetUrl={EducrackAPI.asset.getAssetUrl}
      _id={id}
    />
  );
};
