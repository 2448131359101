import React from "react";
import {
  AllBatches,
  BatchReport,
  EducatorDetail,
  EducatorList,
  StudentDetail,
  StudentList,
  TestList,
  TestReports,
} from "@lipihipi/ec-reports";
import EducrackAPI from "@lipihipi/client-sdk";
import { useHistory, useParams } from "react-router-dom";

export const ReportBatchList = () => {
  const history = useHistory();

  return (
    <AllBatches
      breadCrumbs={[{ title: "Batch Reports", link: "#" }]}
      getAssetUrl={EducrackAPI.asset.getAssetUrl}
      getBatches={EducrackAPI.batchV2.list}
      getEducators={EducrackAPI.educator.list}
      getCourses={(params: any) =>
        EducrackAPI.course.list({ ...params, ...{ all: true } })
      }
      onViewBatchReport={(_id: any) => {
        console.log("Batch_Report: ", _id);
        history.push(`/reports/batches/${_id}`);
      }}
    />
  );
};

export const ReportBatch = () => {
  const { id } = useParams();
  return (
    <BatchReport
      _id={id}
      breadCrumbs={[
        { title: "Reports", link: "/reports/batches" },
        { title: "Batch", link: "#" },
      ]}
      getAssetUrl={EducrackAPI.asset.getAssetUrl}
      getBatchById={EducrackAPI.batchV2.get}
      getBatchStudents={EducrackAPI.batchV2.getBatchStudents}
      getBatchEducators={EducrackAPI.batchV2.list}
      onStudentClick={(id) => {}}
      onEducatorClick={(id) => {}}
    />
  );
};

export const ReportEducatorList = () => {
  const history = useHistory();

  return (
    <EducatorList
      title="Educator Reports"
      breadCrumbs={[{ title: "Educators Report", link: "#" }]}
      getEducators={EducrackAPI.educator.list}
      getAssetUrl={EducrackAPI.asset.getAssetUrl}
      getCourses={EducrackAPI.course.list}
      onReportClick={(id: any) => {
        console.log("Educator Id", id);
        history.push(`/reports/educators/${id}`);
      }}
    />
  );
};

export const ReportEducatorDetail = () => {
  const history = useHistory();
  const { id } = useParams();

  return (
    <EducatorDetail
      _id={id}
      breadCrumbs={[
        { title: "Reports", link: "/reports/educators" },
        { title: "Educator", link: "#" },
      ]}
      getEducatorById={EducrackAPI.educator.get}
      getAssetUrl={EducrackAPI.asset.getAssetUrl}
      getTeacherBatchList={EducrackAPI.batchV2.list}
      onViewBatchReport={(_id: any) => {
        history.push(`/reports/batches/${_id}`);
        console.log("Batch_Report: ", _id);
      }}
    />
  );
};

export const ReportStudentList = () => {
  const history = useHistory();
  return (
    <StudentList
      title={"Reports - by Students"}
      breadCrumbs={[{ title: "Student Reports", link: "#" }]}
      getStudents={EducrackAPI.studentProfile.list}
      getAssetUrl={EducrackAPI.asset.getAssetUrl}
      getCourses={EducrackAPI.course.list}
      onStudentReportClick={(_id: string) => {
        console.log("STUDENT_ID:", _id);
        history.push(`/reports/students/${_id}`);
      }}
      getCenters={EducrackAPI.center.list}
      report={EducrackAPI.user.report}
    />
  );
};

export const ReportStudentDetail = () => {
  const history = useHistory();

  const { id } = useParams();

  return (
    <StudentDetail
      _id={id}
      breadCrumbs={[
        { title: "Reports", link: "/reports/students" },
        { title: "Student", link: "#" },
      ]}
      getBatches={EducrackAPI.batchV2.list}
      getBatch={EducrackAPI.batchV2.get}
      getStudent={EducrackAPI.studentProfile.get}
      getAssetUrl={EducrackAPI.asset.getAssetUrl}
      //@ts-ignore
      getTests={EducrackAPI.testBundleV2.list}
      getInterviews={EducrackAPI.userInterview.list}
      getTestDetails={EducrackAPI.userTest.list}
      getEnrolledTest={EducrackAPI.userTestBundle.get}
      onBatchTestClick={(id: string) => {
        history.push(`/course/batch/${id}`);
        console.log("Batch_Test_ID:", id);
      }}
      onTestBundleClick={(id: string) => {
        history.push(`/course/tests/details/${id}`);
        console.log("Test_Bundle_ID:", id);
      }}
      onInterviewClick={(id: string) => {
        console.log("Interview_ID:", id);
      }}
      getUserTest={EducrackAPI.userTest.list}
      onReportClick={() => {}}
      onPsychometricReportClick={() => {}}
    />
  );
};

export const ReportTestList = () => {
  const history = useHistory();
  return (
    <TestList
      breadCrumbs={[{ title: "Test Reports", link: "#" }]}
      getTests={EducrackAPI.test.testReport}
      getAssetUrl={EducrackAPI.asset.getAssetUrl}
      getCourses={EducrackAPI.course.list}
      onReportClick={(id: any) => {
        console.log("Educator Id", id);
        history.push(`/reports/tests/${id}`);
      }}
    />
  );
};

export const ReportTestReports = () => {
  const { id } = useParams();
  return (
    <TestReports
      testId={id}
      breadCrumbs={[
        { title: "Reports", link: "/reports/tests" },
        { title: "Test", link: "#" },
      ]}
      //@ts-ignore
      getUserTestEnrollment={EducrackAPI.userTest.list}
      getAssetUrl={EducrackAPI.asset.getAssetUrl}
      getCourses={EducrackAPI.course.list}
      onShowResult={(attemptId: any) => {
        if (attemptId) {
          const _window = window as any;
          _window.open(`/course/test/result/${attemptId}`);
        }
      }}
      onCheckAnswer={(_id: any) => {
        console.log("Attempted Test ID: ", _id);
      }}
      onDescriptiveResult={(_id: any) => {
        console.log("Descriptive test id:", _id);
      }}
    />
  );
};
