import React, { useEffect, useState } from "react";
import AuthAPI, { UserRole } from "../../api/auth";
//import { SideBarUser } from "@lipihipi/ec-student-web";
import SideBarUser from "../common/SideBarUser";
import EducrackAPI from "@lipihipi/client-sdk";

export const defaultModules = [
  "Dashboard",
  "Test",
  "Manage Test",
  "Manage Test Bundles",
];

interface IMenuItem {
  name: string;
  link: string;
  icon?: string;
  items?: IMenuItem[];
  resource?: string;
}

const Sidebar = ({
  menu: menuList,
  onCourseSelect,
  toggleDrawer,
}: {
  menu: any;
  onCourseSelect: any;
  toggleDrawer: any;
}) => {
  const [menuItems, setMenuItems] = useState(menuList);

  const currentUser: any = AuthAPI.getCurrentUser();
  const role = currentUser.role;
  useEffect(() => {
    //@ts-ignore
    // const userPermissions: any[] = currentUser.permissions;
    if (menuList?.[0]?.items?.length > 0) {
      //@ts-ignore
      const enumVal = EducrackAPI.auth.GrantResources;

      const permissionValues: any = {};

      const userPermissions = EducrackAPI.getConfig().modules;

      userPermissions &&
        userPermissions.forEach((per: any) => {
          //@ts-ignore
          const permValue = enumVal[per];
          permissionValues[permValue] = permValue;
        });

      const topLevelItems = menuList?.[0]?.items || [];
      const menuListItems = topLevelItems.filter((it: any) => {
        if (Array.isArray(it?.resource)) {
          return userPermissions.some((v: any) => it?.resource.includes(v));
        }
        return [...userPermissions, "MY_RESUME", "LOGOUT"].includes(
          it.resource ? it.resource : it
        );
      });

      menuList[0].items = menuListItems;
      setMenuItems(menuList);
    }
  }, [menuList]);

  if (!currentUser) {
    return null;
  }

  return (
    <SideBarUser
      getCurrentUser={EducrackAPI.user.me}
      onCourseSelect={onCourseSelect}
      menu={menuItems}
      toggleDrawer={toggleDrawer}
      role={role}
    />
  );
};

export default Sidebar;
