import React from "react";
import { ForgotPassword } from "@lipihipi/ec-student-web";
import EducrackAPI from "@lipihipi/client-sdk";

const ForgotPasswordPage = () => {
  return (
    <ForgotPassword
      forgotPasswordOtp={EducrackAPI.auth.login}
      // @ts-ignore
      imageBaseUrl={EducrackAPI.asset.getAssetUrl}
    />
  );
};

export default ForgotPasswordPage;
