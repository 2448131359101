import React, { useContext } from "react";
import {
  ResourceDetail,
  StudentResourceList,
  ResourceDetailPage,
} from "@lipihipi/ec-batch";
import EduCrackAPI from "@lipihipi/client-sdk";
import { useHistory, useParams } from "react-router-dom";
import { AppContext } from "../../App";

export const StuResourceDetails = (props: any) => {
  const { id, _id }: any = useParams();
  return (
    <ResourceDetail
      title={"Manage Program"}
      breadCrumbs={[
        { title: "Program", link: "/course/batch" },
        { title: "Program Details", link: `/course/batch/${id}` },
        { title: "Resource" },
      ]}
      resourceName={_id}
      getAssetUrl={EduCrackAPI.asset.getAssetUrl}
    />
  );
};

export const StuResourceListDetails = (props: any) => {
  const { state } = useContext<any>(AppContext);
  const { id }: any = useParams();
  const history = useHistory();
  //console.log(id, props.selectedCourse._id);
  return (
    <StudentResourceList
      parentName={state?.activeResource?.name}
      parentID={id}
      courseID={props.selectedCourse._id}
      activeCategory={EduCrackAPI.category.activeList}
      cmsRoute="/view-resource"
      getBatches={() => { history.push(`/course/batch`) }}
    />
  );
};

export const StuResourceDetailPage = (props: any) => {
  const { id, _id }: any = useParams();
  return <ResourceDetailPage id={id} />;
};
