import React, { FC } from "react";
import POWERED from '../../../images/scholorship/powered.png';
import EducrackAPI from "@lipihipi/client-sdk";
interface LeftPanelProps {
  title?: string;
  logo?:string;
}

const LeftPanel = ({
  title,logo
}: LeftPanelProps) => {
  console.log(logo)
  return (
    <div className="user-structure--info scholarship">
      <div className="info-header">
        <img
          src={EducrackAPI.asset.getAssetUrl(logo)}
          alt="Logo"
        />
      </div>
      <div className="info-body text-center px-0">
        <img src={POWERED} alt="POWERED" />
      </div>
    </div>
  );
};

export default LeftPanel;
