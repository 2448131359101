import React from "react";
import EducrackAPI from "@lipihipi/client-sdk";
import { PersonalDetail } from "@lipihipi/ec-student-web";

const Register = () => {
  // AuthAPI.logout();

  return (
    <PersonalDetail
      submit={EducrackAPI.user.update}
      imageBaseUrl={EducrackAPI.asset.getAssetUrl}
      centerList={EducrackAPI.center.list}
    />
  );
};

export default Register;
