import React, { useEffect, useState } from "react";
import { ILoginParams } from "@lipihipi/client-sdk/dist/auth";
import LeftPanel from "../component/LeftPanel";
import LoginForm from "./LoginForm";
import EducrackAPI from "@lipihipi/client-sdk";
import { useHistory, useParams } from "react-router-dom";
import { Loader } from "@lipihipi/ec-ui";

const SchorarshipLogin = () => {
  const [error, setError] = useState();
  const history = useHistory();
  const [isLoading, setLoading] = useState<boolean>(false);
  const { centerId }: any = useParams();
  const [logo, setLogo] = useState();
  useEffect(() => {
    (async () => {
      if (centerId) {
        localStorage.setItem("center", centerId);
        const res: any = await EducrackAPI.center.getByCode(centerId);
        setLogo(res.data.logo);
      } else {
        const res: any = await EducrackAPI.getConfig();
        setLogo(res.logo.key);
      }
    })();
  }, []);
  const handleSubmit = async (values: ILoginParams) => {
    try {
      setLoading(true);
      let response;
      response = await EducrackAPI.auth.login(values);
      EducrackAPI.setToken(response.data.token);
      localStorage.setItem("user", JSON.stringify(response.data.user));

      history.push("/scholarship/otp-verify");
    } catch (error) {
      setLoading(false);
      setError(error.data.message);
    }
  };

  return (
    <main className="user-structure">
      {isLoading && <Loader />}
      <LeftPanel logo={logo} />

      <LoginForm handleSubmit={handleSubmit} error={error} />
    </main>
  );
};

export default SchorarshipLogin;
