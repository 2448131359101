import React from "react";
//import { AppBarUser } from "@lipihipi/ec-student-web";
import AppBarUser from "../common/AppBarUser";
import EducrackAPI from "@lipihipi/client-sdk";
import { useHistory } from "react-router-dom";
interface ITopAppBarUser {
  onCourseSelect: any;
  toggleDrawer: any;
}

const manageRedirectionFromNotifcation = (notification: any, history: any) => {
  console.log("view Notification Details and redirect to module", notification);
  switch (notification.module) {
    case "WALL":
      history.push(`/course/wall#${notification.id}`);
      break;
    case "DOUBTS":
      history.push(`/course/doubt#${notification.id}`);
      break;
    case "TEST":
      history.push(`/course/tests/details/${notification.id}`);
      break;
    case "BATCH":
      history.push(`/course/batch/${notification.id}`);
      break;
    case "INTERVIEW":
      history.push(`/course/interviews/${notification.id}`);
      break;
    default:
      break;
  }
};

export const TopAppBarUser = ({
  onCourseSelect,
  toggleDrawer,
}: ITopAppBarUser) => {
  const history = useHistory();
  return (
    <AppBarUser
      toggleDrawer={toggleDrawer}
      getCurrentUser={EducrackAPI.user.me}
      onCourseSelect={onCourseSelect}
      imageBaseUrl={EducrackAPI.asset.getAssetUrl()}
      notifications={EducrackAPI.notifications.list}
      markReadNotifications={EducrackAPI.notifications.markRead}
      viewNotificationDetails={(notification: any) =>
        manageRedirectionFromNotifcation(notification, history)
      }
      viewAllNotifications={() => {
        history.push("/notifications");
      }}
    />
  );
};
