import React, {useEffect} from "react";
import AuthAPI from "../../api/auth";
import {Redirect} from "react-router-dom";

const Logout = () => {
  useEffect(() => {
    AuthAPI.logout();
  }, []);

  return <Redirect
    to={{
      pathname: "/login",
    }}
  />
};

export default Logout;
